import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Organization } from '@/shared/types';
import { CurrencyEnum } from '@/shared/types/graphql/globals.highereducation';

import { FullScreenLoader } from '../../components/ui/FullScreenLoader';
import { useOrganizationQuery } from '../../hooks/useOrganizationQuery';

import { OrganizationContext } from './OrganizationContext';

type Props = {
  organization?: Organization;
};

const EMPTY_ORGANIZATION: Organization = {
  id: 0,
  name: '',
  slug: '',
  logo: '',
  currency: CurrencyEnum.USD,
  domain: 'localhost:4000',
};

export const OrganizationProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  organization,
}) => {
  const { query, push, pathname, asPath } = useRouter();
  const {
    getOrganization,
    organization: organizationFromQuery,
    loading,
    error,
    called,
  } = useOrganizationQuery();

  useEffect(() => {
    if (organization) return;
    if (asPath.includes('[org]')) return;
    if (!query.org && !pathname.includes('404')) push('/404');
  }, [query, pathname, asPath, push, organization]);

  useEffect(() => {
    if (organization) return;

    if (!query.org) return;

    const slug = query.org as string;

    getOrganization(slug);
  }, [query, organization, getOrganization]);

  useEffect(() => {
    if ((called && !loading && !organizationFromQuery) || error) push('/404');
  }, [called, loading, error, organizationFromQuery, push]);

  if (organization)
    return (
      <OrganizationContext.Provider value={organization}>
        {children}
      </OrganizationContext.Provider>
    );

  if (called && !loading && !organizationFromQuery) return <FullScreenLoader />;

  return (
    <OrganizationContext.Provider
      value={organizationFromQuery ?? EMPTY_ORGANIZATION}
    >
      {organizationFromQuery ? children : <FullScreenLoader />}
    </OrganizationContext.Provider>
  );
};
