import { Course } from '@/model';
import { GetCoursesByProductQuery_marketplace_courses_edges } from '@/shared/graphql/types/GetCoursesByProductQuery';

const parseCoursesFromQuery = (
  data: GetCoursesByProductQuery_marketplace_courses_edges[] = [],
): Course[] => {
  const courses = data?.map<Course>(course => {
    return {
      id: course?.node?.item?.id || '',
      image: course?.node?.item?.image || '',
      title: course?.node?.item?.title || '',
      modules: course?.node?.item?.modules || [],
    };
  });

  return courses;
};

export default parseCoursesFromQuery;
