import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { PrimaryButton } from '@crehana/ui';

import { useSharedTranslation } from '@/shared/hooks/useSharedTranslation';

import { CancelButton } from './CancelButton';
import { Modal } from './Modal';

type Props = {
  title: string;
  subtitle?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  showClose?: boolean;
  type?: 'dialog' | 'alertdialog';
  topDecorator?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ConfirmOrCancelModal: FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  subtitle,
  confirmButtonLabel,
  cancelButtonLabel,
  showClose,
  type,
  topDecorator,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const { t } = useSharedTranslation();

  return (
    <Modal
      showClose={showClose}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      role={type}
      topDecorator={topDecorator}
      htmlTitle={title}
    >
      {children && <div className="mb-24">{children}</div>}
      <div className="w-full flex justify-between" style={{ gap: 24 }}>
        <CancelButton
          className="px-0 w-full"
          onClick={onCancel}
          label={cancelButtonLabel ?? t('MODAL_CANCEL_BUTTON_LABEL')}
          htmlProps={{ title: 'Cancel button' }}
        />
        <PrimaryButton
          onClick={onConfirm}
          label={confirmButtonLabel ?? t('MODAL_PRIMARY_BUTTON_LABEL')}
          htmlProps={{ title: 'Confirm button' }}
          className={clsx(
            'px-0 w-full border-2 border-solid',
            type === 'alertdialog' &&
              'bg-red-main text-white hover:bg-red-dark',
            type === 'alertdialog'
              ? 'border-red-main hover:border-red-dark'
              : 'border-primary-main hover:border-primary-lighter',
          )}
        />
      </div>
    </Modal>
  );
};
