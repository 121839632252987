import { FC, ReactNode, useCallback, useState } from 'react';

import { FullScreenLoader } from '@/shared/components/ui/FullScreenLoader';

import type { ConfirmationDialogProps } from './ActionFeedbackContext';
import { ConfirmOrCancelDialogProps } from './ActionFeedbackContext';
import { ActionFeedbackContext } from './ActionFeedbackContext';
import {
  createRenderMethodForConfirmModal,
  createRenderMethodForConfirmOrCancelModal,
} from './utils';

type FeedbackComponent = { render: () => ReactNode };

type Props = {
  customLoader?: ReactNode;
};

export const ActionFeedbackProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  customLoader,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState<FeedbackComponent | null>(null);

  const clear = () => setShowModal(null);

  const showConfirmationDialog = useCallback(
    (props: ConfirmationDialogProps) => {
      const render = createRenderMethodForConfirmModal(props, clear);

      setShowModal({ render });
    },
    [],
  );

  const showConfirmOrCancelDialog = useCallback(
    (props: ConfirmOrCancelDialogProps) => {
      const render = createRenderMethodForConfirmOrCancelModal(props, clear);

      setShowModal({ render });
    },
    [],
  );

  const showConfirmOrCancelAlert = useCallback(
    (props: ConfirmOrCancelDialogProps) => {
      const render = createRenderMethodForConfirmOrCancelModal(
        { ...props, type: 'alertdialog' },
        clear,
      );

      setShowModal({ render });
    },
    [],
  );

  const showLoadingScreen = useCallback((loading = true) => {
    setLoading(loading);
  }, []);

  return (
    <ActionFeedbackContext.Provider
      value={{
        clear,
        showConfirmationDialog,
        showConfirmOrCancelDialog,
        showConfirmOrCancelAlert,
        showLoadingScreen,
      }}
    >
      {children}
      {loading && <FullScreenLoader customLoader={customLoader} />}
      {showModal?.render()}
    </ActionFeedbackContext.Provider>
  );
};
