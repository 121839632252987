import { ChangeEvent, FC, SyntheticEvent, useState } from 'react';

import { Search } from '@crehana/icons';

type Props = {
  id: string;
  label: string;
  placeholder: string;
  className?: string;
  liveSearch?: boolean;

  onSearch?: (searchValue: string) => void;
  onChange?: (searchValue: string) => void;
};

export const SearchInput: FC<React.PropsWithChildren<Props>> = ({
  id,
  label,
  placeholder,
  liveSearch = false,
  className,
  onSearch = () => {},
  onChange = () => {},
}) => {
  const [searchValue, setSearchValue] = useState('');

  const search = () => onSearch(searchValue);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    search();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const cleanValue = event.target.value.replace(/  +/g, ' ').trimStart();

    setSearchValue(cleanValue);
    onChange(cleanValue);
    if (liveSearch) onSearch(cleanValue);
  };

  return (
    <div className={className}>
      <div
        className="flex items-center h-40 px-8 bg-white rounded-5 border border-solid border-gray-main"
        onSubmit={handleSubmit}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div onClick={search} className="flex items-center justify-center p-8">
          <Search color="#A0A2A6" size={24} className="cursor-pointer" />
        </div>

        <label htmlFor={id} hidden>
          {label}
        </label>
        <input
          className="outline-none font-body4 flex-grow"
          type="text"
          role={'searchbox'}
          title={label}
          id={id}
          placeholder={placeholder}
          value={searchValue}
          onChange={handleChange}
          aria-label={label}
        />
      </div>
    </div>
  );
};
