import { FC, useState } from 'react';
import { useRouter } from 'next/router';

import { useMe } from '@crehana/auth';

import { InternalLink } from '@/shared/components/ui/InternalLink';
import { MarketplaceLogo } from '@/shared/components/ui/MarketplaceLogo';
import { useInternalLink } from '@/shared/hooks';
import { AuthenticationType } from '@/shared/types';

import { Authentication } from '../Authentication';

import { MenuLoggin, MenuNoLoggin, SearchInput } from './components';

type Props = {
  showSearch?: boolean;
};

export const MainMenu: FC<React.PropsWithChildren<Props>> = ({
  showSearch = true,
}) => {
  const createLink = useInternalLink();
  const { me } = useMe();
  const { query: currentQuery, push } = useRouter();
  const [showAuthentication, setShowAuthentication] = useState(false);
  const [authenticationType, setAuthenticationType] =
    useState<AuthenticationType>('LOGIN');

  const toggleAuthenticationPopUp = () =>
    setShowAuthentication(!showAuthentication);

  const handleRegisterOpen = () => {
    setAuthenticationType('REGISTER');
    toggleAuthenticationPopUp();
  };

  const handleLoginOpen = () => {
    setAuthenticationType('LOGIN');
    toggleAuthenticationPopUp();
  };

  const onSearchSubmitted = (searchValue: string) => {
    const query = searchValue ? { search: encodeURI(searchValue) } : {};

    if (currentQuery.org)
      void push(
        {
          pathname: createLink('ecommerce').pathname,
          query,
        },
        undefined,
      );
  };

  return (
    <>
      <div
        className="sticky top-0 bg-white w-full px-16 h-72 flex items-center justify-between border-b border-gray-light border-solid shadow-dropdown lg:tw-border-0 lg:tw-px-4"
        style={{ zIndex: 100 }}
      >
        <InternalLink href="ecommerce">
          <div className="h-36 w-96 flex justify-start">
            <MarketplaceLogo />
          </div>
        </InternalLink>
        <div className="flex">
          {showSearch && (
            <div className="items-center w-280 hidden lg:tw-flex">
              <SearchInput onSearch={onSearchSubmitted} />
            </div>
          )}
          <div className="flex items-center border-solid border-gray-light ml-24 sm:tw-border-l sm:tw-pl-2.4">
            {me ? (
              <MenuLoggin me={me} />
            ) : (
              <MenuNoLoggin
                handleLoginOpen={handleLoginOpen}
                handleRegisterOpen={handleRegisterOpen}
              />
            )}
          </div>
        </div>
        {showAuthentication && (
          <Authentication
            initialState={authenticationType}
            onCloseClicked={toggleAuthenticationPopUp}
          />
        )}
      </div>

      {showSearch && (
        <div className="bg-white lg:tw-hidden">
          <div className="tw-container tw-flex tw-py-2.4">
            <SearchInput onSearch={onSearchSubmitted} />
          </div>
        </div>
      )}
    </>
  );
};
