import { getProductOrderFromOrders } from '../utils/getProductOrderFromOrders';

import { OrderStatusEnum, useUserOrders } from './useUserOrders';

export const useWasProductPurchased = (id: string) => {
  const { orders, loading } = useUserOrders([
    OrderStatusEnum.PAID,
    OrderStatusEnum.PENDING,
  ]);

  const order = getProductOrderFromOrders({ product: { id }, orders });

  return {
    loading,
    status: order?.status,
    order,
  };
};
