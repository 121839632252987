import { FC } from 'react';
import clsx from 'clsx';

import { HighedEducationNextSeo } from '../../components/features/HighedEducationNextSeo';
import { MainMenu } from '../../components/features/MainMenu';
import { Ecommerce } from '../../components/hoc/Ecommerce';
import { CheckoutNavBar } from '../../components/ui/CheckoutNavBar';
import { Footer } from '../../components/ui/Footer';

import { EcommerceLayoutProps } from './types';

const EcommerceLayout: FC<React.PropsWithChildren<EcommerceLayoutProps>> = ({
  children,
  branding,
  organization,
  pageTitle,
  pageDescription,
  productId,
  banner,
  shouldBeLogged = false,
  hasCountryCode = false,
  showSearchOnMenu = true,
  showCheckoutNavBar = false,
  removeFooter = false,
  mainInContainer = true,
}) => (
  <Ecommerce
    branding={branding}
    organization={organization}
    shouldBeLogged={shouldBeLogged}
    productId={productId}
  >
    <HighedEducationNextSeo
      hasCountryCode={hasCountryCode}
      title={pageTitle}
      description={pageDescription}
    />
    <div className="tw-flex tw-min-h-screen tw-flex-col">
      {showCheckoutNavBar ? (
        <CheckoutNavBar />
      ) : (
        <MainMenu showSearch={showSearchOnMenu} />
      )}
      {banner}
      <main
        className={clsx(
          'tw-relative tw-flex tw-grow tw-flex-col tw-pt-3.6 lg:tw-pt-5.6',
          mainInContainer && 'tw-container',
        )}
      >
        <div className="tw-flex tw-h-full tw-grow tw-flex-col">{children}</div>
      </main>
      {!removeFooter && <Footer />}
    </div>
  </Ecommerce>
);

export default EcommerceLayout;
