type OrganizationProps = {
  slug: string;
  domain?: string;
};

export default class InternalError extends Error {
  slug: string;
  domain?: string;

  constructor(message: string, { slug, domain }: OrganizationProps) {
    super(message);
    this.slug = slug;
    this.domain = domain;
  }
}
