import { FC } from 'react';

import { User } from '@crehana/icons';

type Props = {
  className?: string;
};

export const UserChip: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  return (
    <div className={className}>
      <div className="rounded-full w-40 h-40 bg-primary-main flex items-center justify-center cursor-pointer">
        <User color="white" size={24} />
      </div>
    </div>
  );
};
