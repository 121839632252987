import { FC, useEffect } from 'react';

import { useMe } from '@crehana/auth';

import { FullScreenEcommerceLoader } from '@/shared/components/ui/FullScreenEcommerceLoader';
import { usePushInternalUrl } from '@/shared/hooks';

type Props = {
  productId?: string;
};

export const AuthorizedEcommerceUser: FC<React.PropsWithChildren<Props>> = ({
  children,
  productId,
}) => {
  const { loading, me } = useMe();
  const { push } = usePushInternalUrl();

  useEffect(() => {
    if (!loading && !me)
      productId ? push(`ecommerce/product/${productId}`) : push('ecommerce');
  }, [loading, me, productId, push]);

  if (loading || !me) return <FullScreenEcommerceLoader />;

  return <>{children}</>;
};
