import Link from 'next/link';

import { MarketplaceLogo } from '@/shared/components/ui/MarketplaceLogo';

export const Menu = () => {
  return (
    <div
      className="sticky top-0 bg-white w-full px-16 h-72 flex items-center justify-between border-b border-gray-light border-solid lg:tw-border-0 lg:tw-px-4"
      style={{ zIndex: 100 }}
    >
      <Link href="/">
        <div className="h-36 w-96 flex justify-start">
          <MarketplaceLogo />
        </div>
      </Link>
    </div>
  );
};
