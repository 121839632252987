import { FC } from 'react';

import { Button } from '@/shared/components/ui';
import { useSharedTranslation } from '@/shared/hooks';

type Props = {
  handleLoginOpen: () => void;
  handleRegisterOpen: () => void;
};

export const MenuNoLoggin: FC<React.PropsWithChildren<Props>> = ({
  handleLoginOpen,
  handleRegisterOpen,
}) => {
  const { t } = useSharedTranslation();

  return (
    <div className="tw-flex tw-gap-2.4">
      <Button
        className="tw-min-w-9.6 sm:tw-hidden"
        variant="secondary"
        onClick={handleLoginOpen}
      >
        {t('ENTER')}
      </Button>

      <Button
        className="tw-hidden sm:tw-block"
        variant="secondary"
        onClick={handleLoginOpen}
      >
        {t('LOGIN')}
      </Button>

      <Button className="tw-hidden sm:tw-block" onClick={handleRegisterOpen}>
        {t('REGISTER')}
      </Button>
    </div>
  );
};
