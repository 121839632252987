import { FC } from 'react';

import { OrderDetail } from '@/model';
import { useSharedTranslation } from '@/shared/hooks';

import { Separator } from '../Separator';

import { OrderCard, OrderPricing } from './components';

type Props = {
  orderDetail: OrderDetail;
};

export const OrderResume: FC<React.PropsWithChildren<Props>> = ({
  orderDetail,
}) => {
  const { t } = useSharedTranslation();

  return (
    <div className="bg-white rounded-10 px-24 py-32 relative">
      <div className="flex items-center pb-16">
        <h3 className="font-h4">{t('ORDER_DETAIL_TITLE')}</h3>
      </div>
      <Separator />
      <OrderCard orderDetail={orderDetail} />
      <OrderPricing orderDetail={orderDetail} />
    </div>
  );
};
