import { ButtonHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

type TPrimaryButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  id?: string;
  full?: boolean;
};

// eslint-disable-next-line react/display-name
export const PrimaryButton = forwardRef<HTMLButtonElement, TPrimaryButtonProps>(
  (
    { text, full = true, className, id, type = 'button', disabled, ...props },
    ref,
  ) => (
    <button
      ref={ref}
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={clsx(
        'tw-btn-s tw-relative tw-justify-center tw-overflow-hidden tw-px-1.6 tw-text-center',
        full && 'tw-w-full',
        disabled
          ? 'disabled:tw-bg-neutral-light-600 disabled:tw-text-neutral-light-800'
          : 'bg-primary before:tw-absolute before:tw-left-0 before:tw-h-full before:tw-w-full before:hover:tw-bg-neutral-light-100 before:hover:tw-opacity-10',
        className,
      )}
      {...props}
    >
      {text}
    </button>
  ),
);
