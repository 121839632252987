import { Order } from '../types';

type Props = {
  product: { id: string };
  orders: Order[];
};

export const getProductOrderFromOrders = ({
  product,
  orders,
}: Props): Order | undefined => {
  const relatedOrders = orders.filter(
    ({ productId }) => product.id === productId,
  );

  const paidOrder = relatedOrders.find(order => order.status === 'PAID');
  const pendingOrder = relatedOrders.find(order => order.status === 'PENDING');

  return paidOrder ?? pendingOrder;
};
