import { createContext, ReactNode } from 'react';

export type ConfirmationDialogProps = {
  title: string;
  subtitle?: string;
  confirmButtonLabel?: string;
  showClose?: boolean;
  topDecorator?: ReactNode;
  body?: ReactNode;

  onClose?: () => void;
  onConfirm?: () => void;
};

export type ConfirmOrCancelDialogProps = ConfirmationDialogProps & {
  cancelButtonLabel?: string;
  onCancel?: () => void;
};

export interface ContextProps {
  clear: () => void;
  showConfirmationDialog: (props: ConfirmationDialogProps) => void;
  showConfirmOrCancelDialog: (props: ConfirmOrCancelDialogProps) => void;
  showConfirmOrCancelAlert: (props: ConfirmOrCancelDialogProps) => void;
  showLoadingScreen: (loading?: boolean) => void;
}

export const ActionFeedbackContext = createContext({} as ContextProps);
