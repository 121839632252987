import Head from 'next/head';
import Link from 'next/link';

import { CustomNextImage } from '@crehana/next';

import { useSharedTranslation } from '@/shared/hooks/useSharedTranslation';

type Props = {
  domain?: string;
  slug: string;
};

const InternalErrorView = ({ domain, slug }: Props) => {
  const { t } = useSharedTranslation();

  return (
    <>
      <Head>
        <title>{t('INTERNAL_ERROR_TITLE')}</title>
      </Head>
      <section className="h-screen" style={{ backgroundColor: '#F7F8FA' }}>
        <div className="tw-container tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
          <div className="relative w-360 h-360 flex justify-center">
            <CustomNextImage
              className="absolute z-2"
              alt="Error image"
              layout="fill"
              objectFit="contain"
              src="https://crehana-vercel-images-prd.imgix.net/public/higher-education-mf/272c8273-f07f-4631-80b4-debf2a0ad193-error.png?auto=format&fit=clip"
            />
          </div>
          <h1 className="font-h3 mb-16">{t('INTERNAL_ERROR_TITLE')}</h1>
          <p className="font-body3 mb-40 tw-text-center">
            {t('INTERNAL_ERROR_MESSAGE')}
          </p>
          <Link
            href={domain ? `${domain}/` : `/org/${slug}/ecommerce/`}
            className="font-subtitle2 border-solid border-2 rounded-10 border-gray-main-dark-mode py-16 px-36 text-gray-main-dark-mode"
          >
            {t('BACK_TO_HOME')}
          </Link>
        </div>
      </section>
    </>
  );
};

export default InternalErrorView;
