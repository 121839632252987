import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';

import { BrandStylesWrapper } from '@/shared/components/ui/BrandStylesWrapper';
import { FullScreenEcommerceLoader } from '@/shared/components/ui/FullScreenEcommerceLoader';
import { useBrandingQuery } from '@/shared/hooks/useBrandingQuery';
import { useOrganization } from '@/shared/hooks/useOrganization';
import type { Branding } from '@/shared/types';

import { BrandingContext } from './BrandingContext';

type Props = {
  branding?: Branding;
};

const EMPTY_BRANDING: Branding = {
  logo: null,
  banner: null,
  isotype: null,
  favicon: null,
  primaryColor: 'black',
  textOverPrimaryColor: 'white',
  name: '',
  facebook: null,
  instagram: null,
  twitter: null,
  linkedin: null,
};

export const BrandingProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  branding,
}) => {
  const { query } = useRouter();
  const { organizationId } = useOrganization();
  const { getBranding, branding: brandingFromQuery } = useBrandingQuery();

  useEffect(() => {
    if (branding) return;

    getBranding(organizationId);
  }, [query, organizationId, getBranding, branding]);

  if (branding) {
    return (
      <BrandingContext.Provider value={{ branding }}>
        <BrandStylesWrapper
          primaryColor={branding?.primaryColor || 'black'}
          textOverPrimaryColor={branding?.textOverPrimaryColor || 'white'}
        >
          {children}
        </BrandStylesWrapper>
      </BrandingContext.Provider>
    );
  }

  return (
    <BrandingContext.Provider
      value={{ branding: brandingFromQuery ?? EMPTY_BRANDING }}
    >
      {brandingFromQuery ? (
        <BrandStylesWrapper
          primaryColor={
            brandingFromQuery?.primaryColor || EMPTY_BRANDING.primaryColor
          }
          textOverPrimaryColor={
            brandingFromQuery?.textOverPrimaryColor ||
            EMPTY_BRANDING.textOverPrimaryColor
          }
        >
          {children}
        </BrandStylesWrapper>
      ) : (
        <FullScreenEcommerceLoader />
      )}
    </BrandingContext.Provider>
  );
};
