import { FC } from 'react';

import { CustomNextImage } from '@crehana/next';

type Props = {
  backgroundImage: string;
};

export const MainImage: FC<React.PropsWithChildren<Props>> = ({
  backgroundImage,
}) => {
  return (
    <div className="hidden relative w-full h-full lg:tw-block">
      <CustomNextImage
        src={backgroundImage}
        objectFit="cover"
        objectPosition="center top"
        alt="Team"
        layout="fill"
      />
    </div>
  );
};
