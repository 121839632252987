import { FC } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
};

export const Separator: FC<React.PropsWithChildren<Props>> = ({
  className,
}) => (
  <div
    className={clsx('border-solid border-b border-gray-main w-full', className)}
  />
);
