import { initializeApolloClient } from '@crehana/apollo-client';
import { getG11nAndTranslationsFromServer } from '@crehana/next';

import { getEcommerceBranding } from '../services/ecommerce/getEcommerceBranding';
import { getOrganization } from '../services/ecommerce/getOrganization';
import InternalError from '../utils/InternalError';
import NotFoundError from '../utils/NotFoundError';

import { getOrganizationSlugFromParams } from './utils/getOrganizationSlugFromParams';
import {
  EcommercePropsResponse,
  EcommercePropsWithCallbackProps,
} from './types';

export const ecommercePropsWithCallback = async <T extends Object = {}>({
  apolloClient: apolloClientFromProps,
  req,
  params,
  pageKey,
  locales,
  callback,
}: EcommercePropsWithCallbackProps<T>): Promise<EcommercePropsResponse<T>> => {
  const org = getOrganizationSlugFromParams(params);

  try {
    if (!org) throw new InternalError('NO_ORGANIZATION_SLUG', { slug: org });

    const apolloClient =
      apolloClientFromProps ??
      initializeApolloClient({
        headers: req.headers,
        pageKey,
      });

    const organization = await getOrganization({
      apolloClient,
      slug: org,
      req,
    });

    const branding = await getEcommerceBranding({
      apolloClient,
      organization,
    });

    const g11nDataPromise = getG11nAndTranslationsFromServer({
      headers: req.headers,
      pageKey,
      defaultTranslations: locales || {},
    });

    const response = await callback({
      apolloClient,
      organization,
      branding,
      g11nDataPromise,
    });

    return {
      props: {
        type: '200',
        pageKey,
        ...response,
      },
    };
  } catch (error) {
    if (error instanceof InternalError)
      return {
        props: {
          type: '401',
          slug: error.slug,
          domain: error.domain,
          pageKey,
        },
      };

    if (error instanceof NotFoundError)
      return {
        props: {
          type: '404',
          organization: error.organization,
          branding: error.branding,
          pageKey,
        },
      };

    if (org)
      return {
        props: {
          type: '401',
          slug: org,
          pageKey,
        },
      };

    return { notFound: true };
  }
};
