import { useRouter } from 'next/router';

import { InternalLink } from '@/shared/components/ui/InternalLink';

type Props = {
  name: string;
  link: string;
};

const MenuItem = ({ link, name }: Props) => {
  const { asPath } = useRouter();

  return (
    <InternalLink
      href={{
        pathname: link,
        query: { source: asPath, sourceDetail: encodeURI('user menu click') },
      }}
    >
      <p className="font-subtitle3 text-black hover:text-base-lighter py-8">
        {name}
      </p>
    </InternalLink>
  );
};

export default MenuItem;
