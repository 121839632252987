const CableOutImage = () => (
  <svg
    width="472"
    height="105"
    viewBox="0 0 472 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.5174 35.7447H4.90473L4.3461 35.3649C4.16734 35.242 0 32.416 0 27.9255C0 23.4351 5.11697 20.6314 5.70911 20.341L6.17837 20.1064H32.4001C33.6291 20.1064 34.6346 21.1117 34.6346 22.3404C34.6346 23.5691 33.6291 24.5745 32.4001 24.5745H7.28443C6.16719 25.2559 4.46898 26.6298 4.46898 27.9255C4.46898 29.4 5.67561 30.6734 6.35713 31.2766H33.5174C34.7464 31.2766 35.7519 32.2819 35.7519 33.5106C35.7519 34.7394 34.7464 35.7447 33.5174 35.7447Z"
      fill="black"
    />
    <path
      d="M32.4001 84.8936H7.82072C4.68126 84.8936 0 82.816 0 77.0745C0 71.333 4.03325 68.7415 6.16719 68.2053L6.43535 68.1383H32.4113C33.6403 68.1383 34.6458 69.1436 34.6458 70.3723C34.6458 71.6011 33.6403 72.6064 32.4113 72.6064H7.061C6.35714 72.8745 4.48017 73.891 4.48017 77.0745C4.48017 80.258 7.27328 80.4255 7.83191 80.4255H32.4113C33.6403 80.4255 34.6458 81.4309 34.6458 82.6596C34.6458 83.8883 33.6403 84.8936 32.4113 84.8936H32.4001Z"
      fill="black"
    />
    <path
      d="M86.0272 101.649H46.9236C38.5219 101.649 34.5333 95.4718 33.606 92.2101L33.5166 91.9085V15.6383C33.5166 11.1702 34.8461 7.69627 37.4605 5.32819C41.1586 1.98829 46.1191 2.16702 47.0129 2.23404H86.0272V101.649ZM37.9856 91.2271C38.4213 92.4 40.5888 97.1808 46.9236 97.1808H81.5582V6.70212H46.7001C46.7001 6.70212 42.9238 6.37818 40.4324 8.66808C38.8124 10.1537 37.9856 12.4995 37.9856 15.6383V91.2271Z"
      fill="black"
    />
    <path
      d="M91.61 105C84.8731 105 82.1135 100.297 81.5995 97.8176C81.3537 96.6112 82.1246 95.4271 83.3312 95.1814C84.5379 94.9356 85.7222 95.7064 85.968 96.9128C86.0462 97.259 86.9623 100.532 91.5989 100.532C95.8779 100.532 96.9728 96.7787 97.1851 95.8404V8.08724C96.9728 7.29415 95.9673 4.46809 92.7161 4.46809C88.3588 4.46809 85.5881 8.02021 85.5546 8.05372C84.806 9.02553 83.4095 9.21543 82.4263 8.47819C81.4431 7.72979 81.2532 6.34468 81.9906 5.3617C82.1582 5.1383 86.0908 0 92.7161 0C98.5816 0 101.095 4.82553 101.609 7.38351L101.654 7.81915V96.2537L101.621 96.4324C101.129 99.3926 98.3917 105 91.5989 105H91.61Z"
      fill="black"
    />
    <path
      d="M55.8585 60.3191H36.8654C35.6364 60.3191 34.6309 59.3138 34.6309 58.0851C34.6309 56.8564 35.6364 55.8511 36.8654 55.8511H51.3896V49.1489H36.8654C35.6364 49.1489 34.6309 48.1436 34.6309 46.9149C34.6309 45.6862 35.6364 44.6808 36.8654 44.6808H55.8585V60.3191Z"
      fill="black"
    />
    <path
      d="M115.075 83.7766H99.4337C98.2047 83.7766 97.1992 82.7713 97.1992 81.5425C97.1992 80.3138 98.2047 79.3085 99.4337 79.3085H115.075C118.416 79.3085 120.538 76.4489 121.22 75.3543V30.7181C121.22 25.8814 115.835 24.742 114.896 24.5745H100.551C99.322 24.5745 98.3165 23.5691 98.3165 22.3404C98.3165 21.1117 99.322 20.1064 100.551 20.1064L115.366 20.1287C118.93 20.609 125.689 23.4351 125.689 30.7181V76.4824L125.454 76.9516C125.32 77.2308 121.969 83.7766 115.075 83.7766Z"
      fill="black"
    />
    <path
      d="M110.607 72.6064C109.378 72.6064 108.372 71.6011 108.372 70.3724V33.5107C108.372 32.2819 109.378 31.2766 110.607 31.2766C111.836 31.2766 112.841 32.2819 112.841 33.5107V70.3724C112.841 71.6011 111.836 72.6064 110.607 72.6064Z"
      fill="black"
    />
    <path
      d="M154.491 65.9042H123.454C122.225 65.9042 121.22 64.8989 121.22 63.6702C121.22 62.4415 122.225 61.4361 123.454 61.4361H153.81C154.983 61.0005 159.765 58.8335 159.765 52.5C159.765 50.3665 159.318 48.4117 158.458 46.7026C157.24 44.301 155.475 42.9494 154.692 42.4468H123.454C122.225 42.4468 121.22 41.4415 121.22 40.2127C121.22 38.984 122.225 37.9787 123.454 37.9787H155.821L156.29 38.2133C156.447 38.2915 160.145 40.1792 162.435 44.6808C163.63 47.0266 164.234 49.6627 164.234 52.5C164.234 60.9 158.055 64.8877 154.793 65.8149L154.491 65.9042Z"
      fill="black"
    />
    <path
      d="M490.38 74.9074C480.403 74.9074 445.042 66.8202 440.808 65.8484L438.652 65.3457L438.685 65.2005C427.692 61.9165 369.807 45.5186 297.03 40.2127C220.107 34.6053 161.977 47.842 161.396 47.976C160.201 48.2553 158.994 47.5069 158.715 46.3117C158.435 45.1053 159.184 43.9101 160.391 43.6308C160.972 43.4968 219.716 30.0925 297.365 35.7558C339.496 38.8276 376.544 45.6638 400.196 50.8468C413.648 53.7957 424.273 56.5324 430.809 58.3085C437.535 60.1404 440.495 60.9446 441.892 61.5032C458.584 65.3569 486.705 71.1654 491.241 70.3946C492.47 70.1824 493.609 71.009 493.81 72.2266C494.011 73.4441 493.196 74.5946 491.978 74.7957C491.565 74.8627 491.028 74.8962 490.403 74.8962L490.38 74.9074Z"
      fill="black"
    />
    <path
      d="M491.667 91.8191C487.343 91.8191 478.17 90.6351 458.06 86.6697C435.38 82.2016 414.23 76.8957 401.482 72.4835C372.512 62.4527 306.204 54.734 271.424 53.617C237.337 52.5223 161.878 60.2298 161.118 60.308C159.878 60.442 158.794 59.5372 158.671 58.3197C158.549 57.091 159.442 55.9963 160.66 55.8734C161.42 55.7952 237.191 48.0543 271.569 49.1601C306.64 50.2883 373.585 58.1074 402.946 68.2723C431.67 78.2138 486.829 88.0213 492.739 87.4181C493.365 87.0718 494.136 87.0271 494.828 87.3734C495.935 87.9207 496.381 89.2723 495.823 90.367C495.465 91.0707 495.097 91.8191 491.667 91.8191Z"
      fill="black"
    />
  </svg>
);

export default CableOutImage;
