import { FC, PropsWithChildren } from 'react';
import { css } from 'styled-components';

import { MainImage } from './MainImage';

type Props = PropsWithChildren<{
  backgroundImage: string;
}>;

export const LayoutPassword: FC<React.PropsWithChildren<Props>> = ({
  children,
  backgroundImage,
}) => (
  <>
    <div
      css={css`
        min-width: 612px;
      `}
    >
      <MainImage backgroundImage={backgroundImage} />
    </div>
    <div className="px-16 py-32 sm:py-52 sm:px-80 w-full lg:tw-px-4.8 lg:tw-py-4">
      {children}
    </div>
  </>
);
