import { useEffect } from 'react';

import { useActionFeedback } from './useActionFeedback';

export const useLoading = (loading: boolean) => {
  const { showLoadingScreen } = useActionFeedback();

  useEffect(() => {
    showLoadingScreen(loading);
  }, [loading, showLoadingScreen]);
};
