import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSharedTranslation } from '@/shared/hooks';

import { ImprovedTextField } from '../ImprovedTextField';

type Props = {
  label?: string;
  name?: string;
  hint?: string;
};

export const PasswordField: FC<React.PropsWithChildren<Props>> = ({
  label,
  hint,
  name = 'password',
}) => {
  const { t } = useSharedTranslation();
  const { register, errors } = useFormContext();

  return (
    <ImprovedTextField
      type="password"
      name={name}
      label={label ?? t('PASSWORD_LABEL')}
      hint={hint}
      errorText={errors[name]?.message}
      maxLength={50}
      ref={register({
        required: { value: true, message: t('REQUIRED_FIELD_ERROR') },
        minLength: { value: 3, message: t('MIN_PASSWORD_LENGTH') },
      })}
    />
  );
};
