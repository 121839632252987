import { ChangeEvent, FC, SyntheticEvent, useRef, useState } from 'react';

import { Search } from '@crehana/icons';

import { useSharedTranslation } from '@/shared/hooks';

type Props = {
  onSearch: (serachedValue: string) => void;
};

export const SearchInput: FC<React.PropsWithChildren<Props>> = ({
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useSharedTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    onSearch(searchValue);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const cleanValue = event.target.value.replace(/  +/g, ' ');

    setSearchValue(cleanValue);
  };

  const focusInput = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  return (
    <form
      className="flex items-center h-44 w-full px-12 py-8 rounded-10 bg-gray-lighter border border-solid border-gray-dark"
      onSubmit={handleSubmit}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div onClick={focusInput} className="flex items-center justify-center">
        <Search color="#A0A2A6" size={24} className="cursor-pointer" />
      </div>

      <label htmlFor="search-product" className="hidden">
        {t('SEARCH_PRODUCT_LABEL')}
      </label>
      <input
        ref={inputRef}
        className="ml-8 outline-none font-body4 flex-grow flex"
        type={'search'}
        id="search-product"
        name="search-product"
        placeholder={t('SEARCH_PLACEHOLDER')}
        value={searchValue}
        onChange={handleChange}
      />
    </form>
  );
};
