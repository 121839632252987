import { IncomingMessage } from 'http';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import ORGANIZATION_QUERY from '@/shared/graphql/OrganizationQuery.highereducation.graphql';
import {
  OrganizationQuery,
  OrganizationQueryVariables,
} from '@/shared/graphql/types/OrganizationQuery';
import { Organization } from '@/shared/types';
import { CurrencyEnum } from '@/shared/types/graphql/globals.highereducation';
import InternalError from '@/shared/utils/InternalError';

import { federationApolloQuery } from '../../services/federationApolloQuery';

type Props = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  slug: string;
  req: IncomingMessage;
};

export const getOrganization = async ({
  apolloClient,
  slug,
  req,
}: Props): Promise<Organization> => {
  const { data, error } = await federationApolloQuery<
    OrganizationQuery,
    OrganizationQueryVariables
  >({
    apolloClient,
    options: {
      query: ORGANIZATION_QUERY,
      variables: {
        slug,
      },
    },
  });

  const { domain: headerDomain } = req.headers;

  let domain = '';

  if (typeof headerDomain === 'string') domain = headerDomain;

  if (!data.organization?.original_id || error)
    throw new InternalError('ORGANIZATION_QUERY_ERROR', { slug, domain });

  return {
    id: data.organization.original_id,
    name: data.organization.name,
    slug: data.organization.slug,
    logo: data.organization.logo,
    currency:
      data.organization.marketplace_config.currencies[0] ?? CurrencyEnum.USD,
    domain,
  };
};
