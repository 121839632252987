import { FC, lazy, Suspense, useState } from 'react';

import { CloseSmall } from '@crehana/icons/outline';
import { CustomNextImage } from '@crehana/next';

import { LOGIN_IMAGE_URL } from '@/shared/constants';
import { AuthenticationType } from '@/shared/types/types';

import { FullScreenEcommerceLoader } from '../../ui';

import { ImageWrapper } from './components/ImageWrapper';
import { AuthenticationContainer } from './components';

type Props = {
  initialState: AuthenticationType;
  onCloseClicked: () => void;
  userWantToBuy?: boolean;
};

const LoginForm = lazy(() => import('./components/LoginForm'));
const RegistrationForm = lazy(() => import('./components/RegistrationForm'));

export const Authentication: FC<React.PropsWithChildren<Props>> = ({
  onCloseClicked,
  initialState,
  userWantToBuy,
}) => {
  const [type, setType] = useState(initialState);

  const toggleType = () => setType(type === 'LOGIN' ? 'REGISTER' : 'LOGIN');

  return (
    <AuthenticationContainer className="z-dialog fixed w-screen h-screen left-0 top-0 flex items-center justify-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className="w-screen h-screen bg-gray-dark-56"
        onClick={onCloseClicked}
      />
      <section className="absolute flex rounded-10 overflow-hidden">
        <ImageWrapper className="bg-gray-light">
          <CustomNextImage
            src={LOGIN_IMAGE_URL}
            layout="fill"
            objectFit="cover"
          />
        </ImageWrapper>
        <article className="p-12 bg-white rounded-10 shadow-card flex flex-col sm:tw-rounded-l-0">
          {/* eslint-disable-next-line react/button-has-type */}
          <button className="flex justify-end mb-4" onClick={onCloseClicked}>
            <CloseSmall color="#73757A" size={24} />
          </button>
          <div className="tw-px-2.4 lg:tw-px-6.4">
            <Suspense fallback={<FullScreenEcommerceLoader />}>
              {type === 'LOGIN' ? (
                <LoginForm
                  onRegisterClicked={toggleType}
                  onCloseClicked={onCloseClicked}
                />
              ) : (
                <RegistrationForm
                  onLoginClicked={toggleType}
                  onCloseClicked={onCloseClicked}
                  userWantToBuy={userWantToBuy}
                />
              )}
            </Suspense>
          </div>
        </article>
      </section>
    </AuthenticationContainer>
  );
};
