import { ecommercePropsWithCallback } from '@/shared/server/ecommercePropsWithCallback';

import {
  EcommercePropsProps,
  EcommercePropsResponse,
  EcommercePropsWrapperCallbackProps,
  EcommerceTAppProps,
} from './types';

export const ecommerceProps = async (
  props: EcommercePropsProps,
): Promise<EcommercePropsResponse> =>
  ecommercePropsWithCallback<EcommerceTAppProps>({
    ...props,
    callback: async ({
      branding,
      g11nDataPromise,
      organization,
    }: EcommercePropsWrapperCallbackProps) => {
      const [g11nData] = await Promise.all([g11nDataPromise]);

      return {
        organization,
        branding,
        ...g11nData,
      };
    },
  });
