import { FC, useEffect } from 'react';
import Head from 'next/head';

import { useBranding } from '../../hooks';

type Props = {
  hasCountryCode?: boolean;
  title?: string | null;
  description?: string;
};

export const HighedEducationNextSeo: FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
}) => {
  const { name, favicon } = useBranding();

  useEffect(() => {
    document.getElementById('favicon')?.remove();
    document.getElementById('lnkfavicon')?.remove();
  }, []);

  return (
    <>
      <Head>
        <title>{`${title} | ${name}`}</title>
        {description && <meta name="description" content={description} />}
        {favicon && <link rel="shortcut icon" href={favicon} />}
      </Head>

      {/*
        TODO:
        Verificar con el equipo de SEO cuales van a ser las propiedades del marketplace.
        Por el momento remuevo el CrehanaNextSeo ya que no deberia tener el SEO de Crehana
       */}

      {/* <CrehanaNextSeo
        hasCountryCode={hasCountryCode}
        titleTemplate={`${title} | ${name}`}
        description={description}
        additionalLinkTags={
          favicon
            ? [
                {
                  href: favicon,
                  rel: 'favicon',
                  type: 'image/x-icon',
                },
              ]
            : []
        }
      /> */}
    </>
  );
};
