import styled from 'styled-components';

export const TextWithEllipsis = styled.span<{ lines?: number }>`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lines ?? 2};
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
`;
