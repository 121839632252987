import {
  DocumentNode,
  MutationHookOptions,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';

export const useFederationMutation = <T, OperationVariables = {}>(
  query: DocumentNode | TypedDocumentNode<T, OperationVariables>,
  options?: MutationHookOptions<T, OperationVariables> | undefined,
) => {
  return useMutation<T, OperationVariables>(query, {
    ...options,
    context: { clientName: 'v5' },
  });
};
