import { FC } from 'react';

import { useBranding } from '@/shared/hooks/useBranding';

import { EcommerceLoaderWrapper } from './EcommerceLoaderWrapper.style';

export const EcommerceLoader: FC<React.PropsWithChildren<unknown>> = () => {
  const { primaryColor } = useBranding();

  return (
    <EcommerceLoaderWrapper color={primaryColor}>
      <div className="lds-ring" aria-label="Loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </EcommerceLoaderWrapper>
  );
};
