import { FC, ReactNode } from 'react';

import { useSharedTranslation } from '@/shared/hooks/useSharedTranslation';

import { Modal } from './Modal';
import { PrimaryButton } from './PrimaryButton';

type Props = {
  title: string;
  subtitle?: string;
  confirmButtonLabel?: string;
  showClose?: boolean;
  topDecorator?: ReactNode;

  onConfirm?: () => void;
  onClose?: () => void;
};

export const ConfirmModal: FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  subtitle,
  confirmButtonLabel,
  showClose,
  topDecorator,
  onConfirm,
  onClose,
}) => {
  const { t } = useSharedTranslation();

  return (
    <Modal
      showClose={showClose}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      htmlTitle={title}
      topDecorator={topDecorator}
    >
      {children && <div className="mb-24">{children}</div>}
      <PrimaryButton
        text={confirmButtonLabel ?? t('MODAL_PRIMARY_BUTTON_LABEL')}
        onClick={onConfirm}
        title="Confirm Button"
      />
    </Modal>
  );
};
