import styled from 'styled-components';

export const AuthenticationContainer = styled.div`
  article {
    width: 335px;

    @media (min-width: 768px) {
      width: 357px;
      height: 604px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    @media (min-width: 1366px) {
      width: 490px;
      height: 634px;
    }
  }
`;
