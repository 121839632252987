import { FC } from 'react';

import { EcommerceLoader } from './EcommerceLoader';

type Props = {
  text?: string;
};

export const FullScreenEcommerceLoader: FC<React.PropsWithChildren<Props>> = ({
  text,
}) => {
  return (
    <div
      role={'status'}
      title="loading"
      className="fixed top-0 left-0 w-screen h-screen flex flex-col items-center justify-center bg-gray-dark-56 z-dialog"
    >
      <EcommerceLoader />

      {text && <p className="mt-8 font-subtitle3 text-base-dark">{text}</p>}
    </div>
  );
};
