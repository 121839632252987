import { ChangeEvent, FC, ReactNode, useState } from 'react';
import clsx from 'clsx';

import { TextArea, TextAreaProps } from '@crehana/ui';

import { filterTextInput } from '@/shared/utils';

import { CharactersCount } from './CharactersCount';

type Props = Omit<TextAreaProps, 'value' | 'defaultValue' | 'label'> & {
  onTextChange?: (value: string) => void;
  value?: string;
  defaultValue?: string;
  label: string | ReactNode;
  hideLabel?: boolean;
};

export const ImprovedTextArea: FC<React.PropsWithChildren<Props>> = ({
  onTextChange,
  onChange,
  endAdornment,
  value: valueFromProps,
  defaultValue,
  maxLength = 300,
  full = true,
  id,
  name,
  label,
  hideLabel = false,
  description,
  ...otherProps
}) => {
  const [value, setValue] = useState<string>(
    defaultValue || valueFromProps || '',
  );
  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    const filteredValue = filterTextInput(value);

    setValue(filteredValue);

    if (onChange) onChange(event);
    if (onTextChange) onTextChange(filteredValue);
  };

  return (
    <div className={clsx(full && 'w-full')}>
      <label
        htmlFor={name}
        className={clsx(
          'font-subtitle3 mb-8 text-base-main',
          hideLabel ? 'hidden' : 'block',
        )}
        hidden={hideLabel}
      >
        <span>{label}</span>
      </label>
      {description && (
        <p className="font-body4 mb-8 text-gray-dark">{description}</p>
      )}
      <TextArea
        onChange={handleOnChange}
        defaultValue={defaultValue}
        maxLength={maxLength}
        value={value}
        full={full}
        id={id ?? name}
        name={name}
        endAdornment={
          endAdornment ?? (
            <CharactersCount
              maxCount={maxLength ?? 300}
              currentCount={value.length}
            />
          )
        }
        {...otherProps}
      />
    </div>
  );
};
