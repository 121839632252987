import styled from 'styled-components';

export const PaginationWrapper = styled.div<{ primaryColor: string }>`
  button:hover {
    color: ${props => props.primaryColor};
    border-color: ${props => props.primaryColor};
  }

  svg {
    fill: ${props => props.primaryColor};
  }
`;
