import Link from 'next/link';

import { OrderStatusEnum, useUserOrders } from '@/shared/hooks';
import { useMyTrackUrl } from '@/shared/hooks/useMyTracksUrl';
import { useSharedTranslation } from '@/shared/hooks/useSharedTranslation';

import { MENU_MAIN_ITEMS, MENU_SECONDARY_ITEMS } from '../../menuItems';

import LogoutMenuItem from './LogoutMenuItem';
import MenuItem from './MenuItem';

const MenuItemList = () => {
  const { t } = useSharedTranslation();
  const paidOrders = useUserOrders([OrderStatusEnum.PAID]);
  const tracksLink = useMyTrackUrl();

  return (
    <ul className="flex flex-col" style={{ gap: 10 }}>
      {paidOrders.orders.length > 0 && (
        <li>
          <Link href={tracksLink}>
            <p className="font-subtitle3 text-black hover:text-base-lighter py-8">
              {t('MY_PATH_NAV')}
            </p>
          </Link>
        </li>
      )}
      {MENU_MAIN_ITEMS.map(({ link, nameKey }) => (
        <li key={link}>
          <MenuItem link={link} name={t(nameKey)} />
        </li>
      ))}
      {MENU_SECONDARY_ITEMS.length > 0 && (
        <span className="border-b border-solid border-gray-main" />
      )}

      {MENU_SECONDARY_ITEMS.map(({ link, nameKey }) => (
        <li key={link}>
          <MenuItem link={link} name={t(nameKey)} />
        </li>
      ))}
      <span className="border-b border-solid border-gray-main" />
      <li>
        <LogoutMenuItem />
      </li>
    </ul>
  );
};

export default MenuItemList;
