import { FC } from 'react';

import { Shield } from '@crehana/icons/outline';
import { colors } from '@crehana/ui';

import { InternalLink } from '@/shared/components/ui/InternalLink';
import { MarketplaceLogo } from '@/shared/components/ui/MarketplaceLogo';
import { useSharedTranslation } from '@/shared/hooks/useSharedTranslation';

export const CheckoutNavBar: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useSharedTranslation();

  return (
    <div className="sticky top-0 h-56  px-16 bg-white shadow-dropdown flex-shrink-0">
      <div className="h-full flex justify-between items-center">
        <InternalLink href="ecommerce" className="h-36 w-96 flex justify-start">
          <MarketplaceLogo />
        </InternalLink>
        <div className="flex items-center font-secure-payment text-green-light">
          <Shield color={colors.greenLight} size={18} />
          <span className="ml-8">{t('SECURE_PAYMENT')}</span>
        </div>
      </div>
    </div>
  );
};
