import { useContext } from 'react';

import { OrganizationContext } from '../context/Organization';

export const useOrganization = () => {
  const { id, name, slug, logo, domain, currency } =
    useContext(OrganizationContext);

  return {
    organizationId: id,
    name,
    organizationSlug: slug,
    logo,
    currency,
    domain,
  };
};
