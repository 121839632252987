import { FC, ReactElement } from 'react';

import { ArrowLeft } from '@crehana/icons/outline';
import { TextLink } from '@crehana/ui';

type Props = {
  children: ReactElement;
  customHref?: string;
  customLabel?: string;
};

export const LayoutComeBack: FC<React.PropsWithChildren<Props>> = ({
  children,
  customHref,
  customLabel,
}) => {
  return (
    <section className="mx-auto">
      <TextLink
        label={customHref || 'Volver'}
        leftIcon={{
          component: ArrowLeft,
        }}
        type="level3"
        className="mb-48 sm:mb-80 lg:tw-mb-9.6"
        href={customLabel || '/'}
      />
      {children}
    </section>
  );
};
