import { I18nTranslation } from '@crehana/i18n';

import { HIGHER_EDUCATION_KEY } from '../constants';

import es from './localeEs';
import pt from './localePt';

export type TKeys = keyof (typeof es)[typeof HIGHER_EDUCATION_KEY];

const locales: I18nTranslation = { es, pt };

export default locales;
