import styled from 'styled-components';

export const UserMenuContainer = styled.nav<{ show: boolean }>`
  right: ${({ show }) => (show ? '0' : '-100%')};
  width: ${({ show }) => (show ? '100%' : '0px')};
  overflow-x: hidden;
  height: 100vh;
  transition: all ease 0.2s;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 375px;
    top: 80px;
    right: 24px;
    left: initial;
    height: auto;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;
