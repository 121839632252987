import { FC } from 'react';

import { CustomNextImage } from '@crehana/next';

import { useBranding } from '../../hooks/useBranding';

export const MarketplaceLogo: FC<React.PropsWithChildren<unknown>> = () => {
  const { logo, name } = useBranding();

  if (!logo) return null;

  return (
    <div className="relative h-full w-full">
      <CustomNextImage
        src={logo}
        layout="fill"
        objectFit="contain"
        objectPosition="center"
        alt={`${name} logo`}
      />
    </div>
  );
};
