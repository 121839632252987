import { PaginationCourses } from '@/views/Product/types';

import GET_COURSES_BY_PRODUCT from '../graphql/GetCoursesByProductQuery.highereducation.graphql';
import {
  GetCoursesByProductQuery,
  GetCoursesByProductQueryVariables,
} from '../graphql/types/GetCoursesByProductQuery';
import parseCoursesFromQuery from '../services/utils/parseCourseFromQuery';

import { useLazyFederationQuery } from './useLazyFederationQuery';

type UseGetCoursesByProductParams = {
  id: string;
  organizationId: number;
  initialCourses: PaginationCourses;
};

export const useGetCoursesByProduct = ({
  id,
  organizationId,
  initialCourses,
}: UseGetCoursesByProductParams) => {
  const [GetMoreCoursesByProduct, { data, error, loading, fetchMore }] =
    useLazyFederationQuery<
      GetCoursesByProductQuery,
      GetCoursesByProductQueryVariables
    >(GET_COURSES_BY_PRODUCT, {
      variables: {
        productId: Number(id),
        organizationId,
        first: 8,
        after: initialCourses.nextCursor,
      },
    });

  const handleGetNextEightCourses = () => {
    if (!fetchMore) return GetMoreCoursesByProduct();

    fetchMore({
      variables: {
        after: data?.marketplace_courses?.page_info?.end_cursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          marketplace_courses: {
            ...prev.marketplace_courses,
            edges: [
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...prev.marketplace_courses?.edges,
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...fetchMoreResult.marketplace_courses?.edges,
            ],
            page_info: {
              ...prev?.marketplace_courses?.page_info,
              end_cursor:
                fetchMoreResult?.marketplace_courses?.page_info?.end_cursor,
              has_next_page:
                fetchMoreResult?.marketplace_courses?.page_info?.has_next_page,
            },
          },
        };
      },
    });
  };

  return {
    listCourses: [
      ...initialCourses.courses,
      ...parseCoursesFromQuery(data?.marketplace_courses?.edges),
    ],
    error,
    loading,
    handleGetNextEightCourses,
    hasNextPage: data
      ? data?.marketplace_courses?.page_info?.has_next_page
      : initialCourses.hasNextPage,
    totalCourses: initialCourses.totalCount,
  };
};
