import { FC } from 'react';

import { ActionFeedbackProvider } from '@/shared/context/ActionFeedback/ActionFeedbackProvider';
import { BrandingProvider } from '@/shared/context/Branding/BrandingProvider';
import { OrganizationProvider } from '@/shared/context/Organization/OrganizationProvider';
import type { Branding, Organization } from '@/shared/types';

import { EcommerceLoader } from '../ui/EcommerceLoader';

import { AuthorizedEcommerceUser } from './AuthorizedEcommerceUser';

export type EcommerceProps = {
  organization?: Organization;
  branding?: Branding;
  shouldBeLogged?: boolean;
  productId?: string;
};

export const Ecommerce: FC<React.PropsWithChildren<EcommerceProps>> = ({
  children,
  organization,
  branding,
  shouldBeLogged = false,
  productId,
}) => (
  <OrganizationProvider organization={organization}>
    <BrandingProvider branding={branding}>
      <ActionFeedbackProvider customLoader={<EcommerceLoader />}>
        {shouldBeLogged ? (
          <AuthorizedEcommerceUser productId={productId}>
            {children}
          </AuthorizedEcommerceUser>
        ) : (
          children
        )}
      </ActionFeedbackProvider>
    </BrandingProvider>
  </OrganizationProvider>
);
