import { FC } from 'react';

import { useTranslation } from '@crehana/i18n';

import { EcommerceLayout } from '@/shared/layout';
import { EcommerceLayoutProps } from '@/shared/layout/EcommerceLayout/types';
import {
  EcommerceSuccessTAppProps,
  EcommerceTAppProps,
} from '@/shared/server/types';

import InternalErrorView from '../ui/InternalErrorView';
import NotFoundView from '../ui/NotFoundView';

type Options<T extends Object> = (props: EcommerceSuccessTAppProps<T>) => Omit<
  EcommerceLayoutProps,
  'organization' | 'branding' | 'pageTitle'
> & {
  pageTitle?: string;
};

const withErrorViewsOnApp =
  <T extends Object>(
    Component: FC<React.PropsWithChildren<EcommerceSuccessTAppProps<T>>>,
    options: Options<T> = () => ({}),
  ) =>
  // eslint-disable-next-line react/display-name
  (props: EcommerceTAppProps<T>) => {
    const { type } = props;
    const { t } = useTranslation(props.pageKey);

    if (type === '401') return <InternalErrorView {...props} />;

    if (type === '404')
      return (
        <EcommerceLayout {...props} pageTitle="Página no encontrada">
          <NotFoundView />
        </EcommerceLayout>
      );

    if (type === '200') {
      const { pageTitle, pageDescription, ...layoutProps } = options(props);

      return (
        <EcommerceLayout
          organization={props.organization}
          branding={props.branding}
          pageTitle={pageTitle ?? t('PAGE_TITLE')}
          pageDescription={pageDescription ?? t('PAGE_DESCRIPTION')}
          {...layoutProps}
        >
          <Component {...props} />
        </EcommerceLayout>
      );
    }

    throw new Error('UNHANDLED_RESPONSE_TYPE');
  };

export default withErrorViewsOnApp;
