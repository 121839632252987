import Link from 'next/link';
import { css } from 'styled-components';

import { LogIn } from '@crehana/icons/outline';
import { colors } from '@crehana/ui';

import { useSharedTranslation } from '@/shared/hooks/useSharedTranslation';

const LogoutMenuItem = () => {
  const { t } = useSharedTranslation();

  return (
    <Link
      href="/logout/"
      className="font-subtitle3 text-gray-dark flex items-center py-8"
    >
      <LogIn
        size={24}
        color={colors.grayDark}
        css={css`
          rotate: calc(180deg);
        `}
        className="mr-8"
      />
      <span>{t('LOGOUT_BUTTON')}</span>
    </Link>
  );
};

export default LogoutMenuItem;
