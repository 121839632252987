import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { CloseSmallOutline } from '@crehana/icons';
import { colors } from '@crehana/ui';

import { CloseButtonContainer } from './styles/CloseButtonContainer';

type Props = {
  showClose?: boolean;
  title?: string;
  subtitle?: string;
  defaultModal?: boolean;
  htmlTitle?: string;
  role?: 'alert' | 'alertdialog' | 'dialog';
  topDecorator?: ReactNode;

  onClose?: () => void;
  onClickOutsideModal?: () => void;
};

export const Modal: FC<React.PropsWithChildren<Props>> = ({
  showClose = true,
  children,
  title,
  subtitle,
  htmlTitle = 'Modal',
  role = 'dialog',
  defaultModal = true,
  topDecorator,
  onClickOutsideModal,
  onClose,
}) => (
  <div
    role={role}
    className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-dialog bg-base-main-80"
    title={htmlTitle}
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <div
      className="absolute w-full h-full opacity-0"
      onClick={onClickOutsideModal}
    />
    <div
      className={clsx(
        'relative bg-white flex flex-col items-center',
        defaultModal && 'py-40 px-96',
      )}
      style={defaultModal ? { width: 564 } : {}}
    >
      {topDecorator && (
        <div className="flex justify-center items-center mb-24">
          {topDecorator}
        </div>
      )}
      {title && <h4 className="font-h4 mb-16 text-center">{title}</h4>}
      {subtitle && <p className="font-body3 mb-24 text-center">{subtitle}</p>}
      {children}

      {showClose && (
        <CloseButtonContainer
          className="absolute cursor-pointer"
          onClick={onClose}
          role="button"
          title="Close button"
        >
          <CloseSmallOutline size={32} color={colors.baseLighter} />
        </CloseButtonContainer>
      )}
    </div>
  </div>
);
