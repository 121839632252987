import { FC } from 'react';

import { CustomNextImage } from '@crehana/next';

import { HIGHER_EDUCATION_LOGO } from '@/shared/constants';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

export const HigherEducationLogo: FC<React.PropsWithChildren<Props>> = ({
  className,
  width,
  height,
}) => {
  return (
    <div className={className} style={{ width, height }}>
      <div className="relative h-full w-full">
        <CustomNextImage
          src={HIGHER_EDUCATION_LOGO}
          layout="fill"
          objectFit="contain"
          alt={'Crehana Higher Education'}
        />
      </div>
    </div>
  );
};
