import { FC } from 'react';

import { OrderDetail as OrderDetailType } from '@/model';
import { useSharedTranslation } from '@/shared/hooks';

import { Separator } from '../../Separator';

type Props = {
  orderDetail: OrderDetailType;
};

export const OrderPricing: FC<React.PropsWithChildren<Props>> = ({
  orderDetail: { discount, total, subtotal, currency },
}) => {
  const { t } = useSharedTranslation();
  const hasDiscount = discount > 0;
  return (
    <>
      {hasDiscount && (
        <div className="flex flex-col font-subtitle4 mt-20" style={{ gap: 17 }}>
          <div className="flex items-center justify-between">
            <p>{t('SUBTOTAL')}</p>
            <div>${subtotal}</div>
          </div>
          <div className="flex items-center justify-between">
            <p>{t('DISCOUNT')}</p>
            <div>${discount}</div>
          </div>
        </div>
      )}
      <Separator className="my-20" />
      <div className="flex items-center justify-between font-currency text-secondary-main">
        <p>{t('TOTAL')}</p>
        <div>
          ${total.toLocaleString()} {currency}
        </div>
      </div>
    </>
  );
};
