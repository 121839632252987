import { useTranslation } from '@crehana/i18n';

import { HIGHER_EDUCATION_KEY } from '../constants';
import locales, { TKeys } from '../i18n';

export function useSharedTranslation() {
  const translations = useTranslation<TKeys>(HIGHER_EDUCATION_KEY);
  translations.addResourceBundle(locales);
  return translations;
}
