import { css } from 'styled-components';

import { CustomNextImage } from '@crehana/next';
import { MeObject } from '@crehana/web';

import { IMG_USER } from '@/shared/constants';

type Props = {
  me: MeObject;
};

const UserMenuChip = ({ me }: Props) => {
  const emailUsername = me?.email;
  const usernameWihtoutEmail = emailUsername?.split('@');
  const username = usernameWihtoutEmail && usernameWihtoutEmail[0].split('+');

  return me ? (
    <div className="flex items-center mb-12">
      <div
        className="relative w-48 h-48 mr-16 rounded-full overflow-hidden"
        css={css`
          flex: 1 1 1;
        `}
      >
        <CustomNextImage
          src={me?.profilePictureUrl || IMG_USER}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />
      </div>
      <h4
        className="font-h4 overflow-hidden"
        css={css`
          flex: 1;
        `}
      >
        {me?.fullName || (username && username[0])}
      </h4>
    </div>
  ) : null;
};

export default UserMenuChip;
