import { Dropdown as CrehanaDropdown, DropdownProps } from '@crehana/ui';

import { useBranding } from '../../../hooks';

import { DropdownWrapper } from './DropdownWrapper';

type Props<T> = DropdownProps<T> & { hideLabel?: boolean };

export const Dropdown = <T,>({
  className = '',
  label,
  hideLabel = false,
  ...props
}: Props<T>) => {
  const { primaryColor } = useBranding();

  return (
    <DropdownWrapper primaryColor={primaryColor}>
      <CrehanaDropdown<T>
        {...props}
        className={`dropdown-field ${className}`.trim()}
        label={label && !hideLabel ? label : undefined}
        htmlProps={{ 'aria-label': label }}
      />
    </DropdownWrapper>
  );
};
