/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CurrencyEnum {
  ARS = "ARS",
  BRL = "BRL",
  CLP = "CLP",
  COP = "COP",
  EUR = "EUR",
  MXN = "MXN",
  PEN = "PEN",
  USD = "USD",
}

export enum OrderDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ProductFieldEnum {
  BEST_SELLER = "BEST_SELLER",
  CREATED_AT = "CREATED_AT",
  TITLE = "TITLE",
}

export enum UploadToEnum {
  MARKETPLACE_CONFIG_LOGO = "MARKETPLACE_CONFIG_LOGO",
  PRODUCT_COVER_IMAGE = "PRODUCT_COVER_IMAGE",
}

export interface EpaycoCCPaymentInput {
  invoice_data_id: number;
  product_id: number;
  gateway_org_id?: number | null;
  payment_method_id?: number | null;
  card_token: string;
}

export interface EpaycoPSEPaymentInput {
  invoice_data_id: number;
  product_id: number;
  gateway_org_id?: number | null;
  payment_method_id?: number | null;
  pse_bank_id: string;
}

export interface FlywireTokenizeDataInput {
  invoice_data_id: number;
  product_id: number;
  gateway_org_id?: number | null;
  payment_method_id?: number | null;
}

export interface FlywireValidatePaymentOrderInput {
  order_id: number;
}

export interface GenerateDatafastCheckoutIdInput {
  invoice_data_id: number;
  product_id: number;
  gateway_org_id?: number | null;
  payment_method_id?: number | null;
}

export interface InvoiceDataInput {
  organization_id: number;
  document: string;
  document_type: string;
  name: string;
  last_name: string;
  birthday: string;
  email: string;
  phone_code: string;
  phone_number: string;
  country_iso: string;
  country_name: string;
  address_name: string;
  street_number: string;
  province: string;
  city: string;
  postal_code: string;
}

export interface ProductOrderByFieldInput {
  field: ProductFieldEnum;
  direction: OrderDirectionEnum;
}

export interface QuickEnrollInput {
  invoice_data_id: number;
  product_id: number;
}

export interface ValidatePaymentOrderDatafastInput {
  order_id: number;
  session_id?: string | null;
  resource_path?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
