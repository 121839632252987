import { useRouter } from 'next/router';

import { useInternalLink } from './useInternalLink';

export const usePushInternalUrl = () => {
  const { push } = useRouter();
  const createInternalLink = useInternalLink();

  const pushUrl = (url: string, shallow = false) =>
    push(createInternalLink(url), undefined, { shallow });

  return { push: pushUrl };
};
