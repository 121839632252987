import { UserMenuItem } from './types';

export const MENU_MAIN_ITEMS: UserMenuItem[] = [
  { nameKey: 'SHOPPING_HISTORY', link: 'ecommerce/shopping-history' },
];

export const MENU_SECONDARY_ITEMS: UserMenuItem[] = [
  // { nameKey: 'CONFIG_PROFILE', link: 'ecommerce/settings' },
  // { nameKey: 'HELP_CENTER', link: '/' },
];
