import { Branding, Organization } from '../types';

type NotFoundErrorProps = {
  organization: Organization;
  branding: Branding;
};

export default class NotFoundError extends Error {
  organization: Organization;
  branding: Branding;

  constructor(message: string, { organization, branding }: NotFoundErrorProps) {
    super(message);
    this.organization = organization;
    this.branding = branding;
  }
}
