const CableInImage = () => (
  <svg
    width="456"
    height="107"
    viewBox="0 0 456 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-8.19546 78.6278L-9.2334 74.1918C-9.2334 74.1918 44.3743 61.6479 83.1544 52.525C122.117 43.3565 186.286 33.059 238.845 34.2222C265.991 34.8152 288.506 36.9591 302.604 38.6582C310.622 39.6161 316.827 40.5398 320.614 41.1442C325.602 41.9349 328.286 42.7103 328.666 43.4705L326.625 44.4855L324.378 44.0977L326.625 44.4855L326.146 46.7206C325.815 46.6521 291.015 39.9354 238.742 38.7837C186.594 37.6433 122.904 47.8609 84.1923 56.9724C45.4123 66.0839 -8.19546 78.6278 -8.19546 78.6278Z"
      fill="black"
    />
    <path
      d="M-8.71882 93.5095C-9.97347 93.5095 -11 92.4832 -11 91.2288C-11 89.9744 -9.99628 88.9709 -8.75304 88.9481C-6.83685 88.8226 10.1465 84.6261 28.1108 80.1787C65.7959 70.862 117.408 58.1127 135.862 55.889C163.556 52.5706 243.044 48.3627 267.475 50.1758C298.031 52.4337 324.459 56.9952 324.721 57.0408C325.964 57.2575 326.797 58.432 326.58 59.675C326.364 60.918 325.189 61.7505 323.946 61.5338C323.683 61.4882 297.461 56.961 267.133 54.7145C244.903 53.0724 165.803 56.8812 136.41 60.4049C118.24 62.583 64.6667 75.8225 29.2172 84.5919C2.5388 91.1832 -6.96231 93.4981 -8.70741 93.4981L-8.71882 93.5095Z"
      fill="black"
    />
    <path
      d="M361.97 67.2812H329.247L328.631 66.7909C323.236 62.5487 320.555 57.8847 320.67 52.9127C320.852 44.896 328.403 39.4222 328.722 39.1942L329.315 38.7722H361.982C363.236 38.7722 364.263 39.7985 364.263 41.0529C364.263 42.3073 363.236 43.3337 361.982 43.3337H330.832C329.349 44.5652 325.323 48.3512 325.232 53.0495C325.163 56.3224 327.057 59.5724 330.855 62.7198H361.982C363.236 62.7198 364.263 63.7461 364.263 65.0005C364.263 66.2549 363.236 67.2812 361.982 67.2812H361.97Z"
      fill="black"
    />
    <path
      d="M385.923 84.3867H371.095C364.149 84.3867 361.366 78.6621 360.864 75.6401L360.83 75.2638V29.6494C360.83 23.5827 367.081 21.0739 370.776 20.5493L371.095 20.5265H384.782C386.037 20.5265 387.064 21.5528 387.064 22.8072C387.064 24.0616 386.037 25.0879 384.782 25.0879H371.289C370.4 25.2476 365.392 26.3195 365.392 29.6494V75.0471C365.598 75.9708 366.716 79.8252 371.095 79.8252H385.923C387.178 79.8252 388.204 80.8515 388.204 82.1059C388.204 83.3603 387.178 84.3867 385.923 84.3867Z"
      fill="black"
    />
    <path
      d="M441.813 103.773H397.045L396.771 103.704C386.278 101.082 384.852 91.9358 384.795 91.5481L384.772 91.2288V12.2246L384.864 11.9167C387.042 4.29914 394.707 2.2807 398.46 2.2807H441.802V103.773H441.813ZM397.616 99.2113H437.251V6.84214H398.471C398.186 6.84214 391.251 6.96758 389.346 12.8861V91.0349C389.529 91.9586 390.897 97.3981 397.616 99.2113Z"
      fill="black"
    />
    <path
      d="M422.425 38.7723H404.176C402.921 38.7723 401.895 37.7459 401.895 36.4915C401.895 35.2371 402.921 34.2108 404.176 34.2108H422.425C423.68 34.2108 424.706 35.2371 424.706 36.4915C424.706 37.7459 423.68 38.7723 422.425 38.7723Z"
      fill="black"
    />
    <path
      d="M422.425 50.1758H404.176C402.921 50.1758 401.895 49.1495 401.895 47.8951C401.895 46.6407 402.921 45.6144 404.176 45.6144H422.425C423.68 45.6144 424.706 46.6407 424.706 47.8951C424.706 49.1495 423.68 50.1758 422.425 50.1758Z"
      fill="black"
    />
    <path
      d="M423.566 61.5794H404.176C402.921 61.5794 401.895 60.5531 401.895 59.2987C401.895 58.0443 402.921 57.018 404.176 57.018H423.566C424.82 57.018 425.847 58.0443 425.847 59.2987C425.847 60.5531 424.82 61.5794 423.566 61.5794Z"
      fill="black"
    />
    <path
      d="M423.566 72.9831H403.035C401.78 72.9831 400.754 71.9567 400.754 70.7024C400.754 69.448 401.78 68.4216 403.035 68.4216H423.566C424.82 68.4216 425.847 69.448 425.847 70.7024C425.847 71.9567 424.82 72.9831 423.566 72.9831Z"
      fill="black"
    />
    <path
      d="M376.801 74.1234C375.546 74.1234 374.52 73.0971 374.52 71.8427V34.2108C374.52 32.9564 375.546 31.9301 376.801 31.9301C378.055 31.9301 379.082 32.9564 379.082 34.2108V71.8427C379.082 73.0971 378.055 74.1234 376.801 74.1234Z"
      fill="black"
    />
    <path
      d="M446.081 107C441.302 107 437.937 103.134 437.801 102.975C436.979 102.017 437.093 100.58 438.051 99.7587C439.01 98.9377 440.435 99.0517 441.257 99.9982C441.302 100.055 443.526 102.541 446.252 102.439C447.838 102.382 449.412 101.412 450.94 99.5648V9.33955C450.758 8.48428 449.731 4.56144 446.378 4.56144C442.739 4.56144 441.872 6.89918 441.736 7.36673C441.382 8.5641 440.139 9.28253 438.941 8.95183C437.744 8.62112 437.014 7.41234 437.321 6.21496C437.869 4.07109 440.458 0 446.378 0C452.297 0 454.966 5.72461 455.468 8.74656L455.502 9.12288V101.116L455.046 101.72C452.491 105.118 449.583 106.897 446.378 107C446.287 107 446.184 107 446.093 107H446.081Z"
      fill="black"
    />
  </svg>
);

export default CableInImage;
