import styled from 'styled-components';

export const ImageWrapper = styled.div`
  display: none;
  position: relative;

  @media (min-width: 768px) {
    overflow: hidden;
    display: block;
    width: 334px;
    height: 604px;
  }

  @media (min-width: 1366px) {
    width: 466px;
    height: 634px;
  }
`;
