import { FC } from 'react';

import { Pagination as CrehanaPagination } from '@crehana/ui';

import { useBranding } from '@/shared/hooks';

import { PaginationWrapper } from './PaginationWrapper';

type Props = {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};

export const Pagination: FC<React.PropsWithChildren<Props>> = ({
  onPageChange,
  currentPage,
  totalPages,
}) => {
  const { primaryColor } = useBranding();

  if (totalPages <= 1) return null;

  return (
    <PaginationWrapper
      primaryColor={primaryColor}
      title="Pagination"
      role={'navigation'}
    >
      <CrehanaPagination
        onPageChange={onPageChange}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </PaginationWrapper>
  );
};
