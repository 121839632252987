import { FC } from 'react';
import Link from 'next/link';

import { useInternalLink } from '@/shared/hooks/useInternalLink';

export const InternalLink: FC<
  React.PropsWithChildren<React.ComponentProps<typeof Link>>
> = ({ children, href, ...otherProps }) => {
  const createInternalLink = useInternalLink();

  return (
    <Link href={createInternalLink(href)} {...otherProps}>
      {children}
    </Link>
  );
};
