import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';

import { useMe } from '@crehana/auth';
import { getNextUrl } from '@crehana/utils';

import LOGIN_USER_MARKETPLACE from '@/shared/graphql/LoginUserMarketplace.auth.graphql';

import type {
  LoginUserMarketplace,
  LoginUserMarketplaceVariables,
} from '../graphql/types/LoginUserMarketplace';

import { useOrganization } from './useOrganization';

type Props = {
  email: string;
  password: string;
};

export const useLoginUser = () => {
  const nextUrl = getNextUrl();
  const { refetch, me } = useMe();
  const { organizationSlug } = useOrganization();
  const [logginIn, setLogginIn] = useState(false);
  const [loginMutation] = useMutation<
    LoginUserMarketplace,
    LoginUserMarketplaceVariables
  >(LOGIN_USER_MARKETPLACE, {
    context: { clientName: 'auth' },
  });

  const logIn = useCallback(
    async ({ email, password }: Props) => {
      if (me) return { success: true };

      setLogginIn(true);

      return loginMutation({
        variables: {
          email,
          password,
          nextUrl,
          organizationSlug,
        },
      })
        .then(async res => {
          if (!res.data) return { success: false };
          if (!res.data.loginUserMarketplace) return { success: false };

          if (res.data.loginUserMarketplace.success) await refetch();

          return { success: res.data.loginUserMarketplace.success };
        })
        .catch(() => {
          return { success: false };
        })
        .finally(() => setLogginIn(false));
    },
    [loginMutation, me, nextUrl, organizationSlug, refetch],
  );

  return {
    logIn,
    logginIn,
  };
};
