import { useEffect } from 'react';

import { useMe } from '@crehana/auth';

import USER_ORDERS_QUERY from '@/shared/graphql/UserOrdersQuery.highereducation.graphql';

import { PAYMENT_STATUS } from '../constants';
import {
  UserOrdersQuery,
  UserOrdersQueryVariables,
} from '../graphql/types/UserOrdersQuery';
import { Order } from '../types';

import { useLazyFederationQuery } from './useLazyFederationQuery';
import { useOrganization } from './useOrganization';

export enum OrderStatusEnum {
  PAID = 1,
  FAILED = 2,
  PENDING = 3,
}

export const useUserOrders = (status?: OrderStatusEnum[]) => {
  const { organizationId } = useOrganization();

  const { me, loading: loadingUser } = useMe();

  const [
    getUserPurhcasedProducts,
    { data, loading: loadingQuery, error, called },
  ] = useLazyFederationQuery<UserOrdersQuery, UserOrdersQueryVariables>(
    USER_ORDERS_QUERY,
    {
      variables: {
        organizationId,
        status,
      },
    },
  );

  useEffect(() => {
    if (me) getUserPurhcasedProducts();
  }, [me, getUserPurhcasedProducts]);

  const loading = loadingUser || loadingQuery || Boolean(me && !called);

  const orders: Order[] =
    data?.payment_orders.edges.map(edge => ({
      id: edge.node.id,
      productId: edge.node.product?.id || '',
      status: PAYMENT_STATUS.get(edge.node.status) || 'UNKNOWN',
    })) || [];

  return {
    error,
    loading,
    orders,
  };
};
