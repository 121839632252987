import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import BRANDING_QUERY from '@/shared/graphql/BrandingQuery.highereducation.graphql';
import {
  BrandingQuery,
  BrandingQueryVariables,
} from '@/shared/graphql/types/BrandingQuery';
import { Branding, Organization } from '@/shared/types';
import InternalError from '@/shared/utils/InternalError';

import { federationApolloQuery } from '../../services/federationApolloQuery';

type Props = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  organization: Organization;
};

export const getEcommerceBranding = async ({
  apolloClient,
  organization,
}: Props): Promise<Branding> => {
  const { slug, domain, id } = organization;

  const { data, error } = await federationApolloQuery<
    BrandingQuery,
    BrandingQueryVariables
  >({
    apolloClient,
    options: {
      query: BRANDING_QUERY,
      variables: {
        organizationId: id,
      },
    },
  });

  if (error) throw new InternalError('BRANDING_QUERY_ERROR', { slug, domain });

  return {
    logo: data.marketplace?.logo_image?.url ?? null,
    banner: data.marketplace?.banner?.url ?? null,
    isotype: data.marketplace?.isotype?.url ?? null,
    favicon: data.marketplace?.favicon?.url ?? null,
    primaryColor: data.marketplace?.primary_color || 'black',
    textOverPrimaryColor: data.marketplace?.text_over_primary_color || 'white',
    name: data.marketplace?.organization_name || '',
    facebook: data.marketplace?.facebook_url,
    twitter: data.marketplace?.twitter_url,
    instagram: data.marketplace?.instagram_url,
    linkedin: data.marketplace?.linkedin_url,
  };
};
