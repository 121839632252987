/* eslint-disable no-useless-escape */
import { useCallback } from 'react';
import { LinkProps } from 'next/link';

import { useOrganization } from './useOrganization';

const ECOMMERCE_REGEX = /([\/]?ecommerce)((.*))?/;
const DETECT_INTERNAL_REGEX = /localhost:|qa.creha.co/;

const isFromACrehanaHostOrLocal = (domain: string) =>
  !domain || DETECT_INTERNAL_REGEX.test(domain);

const isInsideMarketplace = (path: string) => ECOMMERCE_REGEX.test(path);

const cleanPath = (path: string) => path.trim().replace(/\/{2,}/g, '/');

export const useInternalLink = () => {
  const { organizationSlug, domain } = useOrganization();

  const createPath = useCallback(
    (url: LinkProps['href']) => {
      const pathname = typeof url === 'string' ? url : url.pathname ?? '';
      const query = typeof url !== 'string' ? url.query : undefined;

      // Asbolute paths are returned as given
      if (pathname.includes('http')) return { pathname, query };

      // This is because we need to support the case where a path is sent with an initial slash or end slash
      // And the adition of the last slash is to simplify the ECOMMERCE_REGEX exec
      const path = cleanPath(pathname + '/');

      if (isFromACrehanaHostOrLocal(domain))
        return {
          pathname: cleanPath(`/org/${organizationSlug}/${path}`),
          query,
        };

      if (isInsideMarketplace(path)) {
        const regexGroups = ECOMMERCE_REGEX.exec(path);

        if (regexGroups && regexGroups[2])
          return { pathname: cleanPath(regexGroups[2]), query };
      }

      return { pathname: path, query };
    },
    [organizationSlug, domain],
  );

  return createPath;
};
