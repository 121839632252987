import styled from 'styled-components';

export const Heading = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  letter-spacing: 10px;

  @media (min-width: 768px) {
    font-size: 150px;
  }
`;
