import { FC } from 'react';
import clsx from 'clsx';

import { PrimaryButton, PrimaryButtonProps } from '@crehana/ui';

export const CancelButton: FC<React.PropsWithChildren<PrimaryButtonProps>> = ({
  className,
  ...props
}) => (
  <PrimaryButton
    className={clsx(
      'bg-transparent text-base-lighter border-solid border-2 border-base-lighter hover:bg-transparent',
      className,
      'cancel-btn',
    )}
    {...props}
  />
);
