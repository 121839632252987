import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { REGEX_EMAIL } from '@crehana/constants';

import { useSharedTranslation } from '@/shared/hooks';

import { ImprovedTextField } from '../../ImprovedTextField';

type Props = {
  autofocus?: boolean;
};

export const EmailInput: FC<React.PropsWithChildren<Props>> = ({
  autofocus,
}) => {
  const { t } = useSharedTranslation();
  const { register, errors } = useFormContext<{ email: string }>();

  return (
    <ImprovedTextField
      autoFocus={autofocus}
      name="email"
      label={t('EMAIL_LABEL')}
      hint={t('EMAIL_HINT')}
      errorText={errors.email?.message}
      ref={register({
        required: { value: true, message: t('REQUIRED_FIELD_ERROR') },
        pattern: {
          value: REGEX_EMAIL,
          message: t('INVALID_EMAIL'),
        },
      })}
    />
  );
};
