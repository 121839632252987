import { FC } from 'react';

import { Container } from '@crehana/ui';

import { useBranding, useSharedTranslation } from '@/shared/hooks';

import { MarketplaceLogo } from '../MarketplaceLogo';

import { FooterSocialMediaLink } from './FooterSocialMediaLink';

export const Footer: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useSharedTranslation();
  const { twitter, instagram, facebook, linkedin } = useBranding();

  return (
    <footer className="relative py-64 bg-white mt-120">
      <Container>
        <div className="flex flex-col justify-between items-center lg:tw-flex-row">
          <div
            className="flex order-last w-160 lg:w-212 justify-center lg:tw-justify-end"
            style={{ gap: 30 }}
          >
            {facebook && (
              <FooterSocialMediaLink link={facebook} socialMedia="Facebook" />
            )}
            {twitter && (
              <FooterSocialMediaLink link={twitter} socialMedia="Twitter" />
            )}
            {instagram && (
              <FooterSocialMediaLink link={instagram} socialMedia="Instagram" />
            )}
            {linkedin && (
              <FooterSocialMediaLink link={linkedin} socialMedia="Linkedin" />
            )}
          </div>
          <div className="h-40 w-280 lg:w-144 mb-32 lg:tw-mb-0">
            <MarketplaceLogo />
          </div>
          <h5 className="font-caption text-center w-full mb-32 text-gray-dark lg:tw-mb-0">
            {t('FOOTER_COPYRIGHTS', {
              interpolation: {
                currentYear: new Date().getFullYear(),
              },
            })}
          </h5>
        </div>
      </Container>
    </footer>
  );
};
