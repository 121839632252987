import { ChangeEvent, FocusEvent, forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

import { TextField, TextFieldProps } from '@crehana/ui';

import { filterTextInput } from '@/shared/utils';

type Props = Omit<TextFieldProps, 'label'> & {
  onTextChange?: (value: string, previousValue: string) => void;
  sizeType?: 'small' | 'large';
  label: string | ReactNode;
  hideLabel?: boolean;
};

// eslint-disable-next-line react/display-name
export const ImprovedTextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      onTextChange = () => {},
      onChange,
      maxLength = 100,
      onBlur = () => {},
      inputClassName,
      sizeType = 'large',
      adornmentClassNames,
      errorText,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      htmlProps,
      name,
      full = true,
      label,
      description,
      hideLabel = false,
      id,
      className,
      inputProps,
      ...otherProps
    },
    ref,
  ) => {
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event);
      if (onTextChange)
        onTextChange(
          filterTextInput(event.target.value),
          event.target.defaultValue,
        );
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
      onTextChange(e.target.value.trim(), e.target.defaultValue);
      onBlur(e);
    };

    return (
      <div className={clsx(className, full && 'w-full')}>
        <label
          htmlFor={name}
          className={clsx(
            'font-subtitle3 mb-8 text-base-main',
            hideLabel ? 'hidden' : 'block',
          )}
          hidden={hideLabel}
        >
          <span>{label}</span>
        </label>
        {description && (
          <p className="font-body4 mb-8 text-gray-dark">{description}</p>
        )}
        <TextField
          full={full}
          id={id ?? name}
          ref={ref}
          onChange={handleOnChange}
          maxLength={maxLength}
          onBlur={handleBlur}
          inputClassName={clsx(inputClassName, sizeType === 'small' && 'h-40')}
          name={name}
          adornmentClassNames={{
            start: clsx(
              adornmentClassNames?.start,
              sizeType === 'small' && 'h-40',
            ),
            end: clsx(adornmentClassNames?.end, sizeType === 'small' && 'h-40'),
          }}
          inputProps={{
            ...inputProps,
            'aria-errormessage': `${name}Error`,
            'aria-invalid': !!errorText,
            'aria-label': typeof label === 'string' ? label : undefined,
          }}
          {...otherProps}
        />
        {errorText && (
          <span
            id={`${name}Error`}
            role={'alert'}
            className="inline-flex font-caption mt-8 text-red-main"
          >
            {errorText}
          </span>
        )}
      </div>
    );
  },
);
