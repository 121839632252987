import { useRef, useState } from 'react';

import { Close } from '@crehana/icons';
import { CustomNextImage } from '@crehana/next';
import { useOnClickOutside } from '@crehana/react-hooks';
import { colors } from '@crehana/ui';
import { MeObject } from '@crehana/web';

import { IMG_USER } from '@/shared/constants';
import { useSharedTranslation } from '@/shared/hooks';

import { UserMenuContainer } from './styles/UserMenuContainer.styles';
import MenuItemList from './MenuItemList';
import UserMenuChip from './UserMenuChip';

type Props = {
  me: MeObject;
};

export const MenuLoggin = ({ me }: Props) => {
  const { t } = useSharedTranslation();
  const innerRef = useRef<HTMLSpanElement>(null);
  const [show, setShow] = useState(false);

  useOnClickOutside(innerRef, () => setShow(false));

  const toggleShow = () => setShow(prev => !prev);

  return (
    <span ref={innerRef}>
      <button
        type="button"
        onClick={toggleShow}
        className="rounded-full relative h-32 w-32 overflow-hidden"
      >
        <CustomNextImage
          src={me?.profilePictureUrl || IMG_USER}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />
      </button>
      <UserMenuContainer
        show={show}
        className="absolute bg-white top-0 rounded-10"
      >
        <div className="flex items-center justify-between mb-40 p-20 sm:tw-hidden">
          <p className="font-body3">{t('MY_ACCOUNT')}</p>
          <button type="button" onClick={() => setShow(false)}>
            <Close size={16} color={colors.black} />
          </button>
        </div>

        <div className="px-40 sm:tw-p-2">
          <UserMenuChip me={me} />

          <MenuItemList />
        </div>
      </UserMenuContainer>
    </span>
  );
};
