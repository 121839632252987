import { FC, ReactNode } from 'react';

import { Loader } from '@crehana/ui';

type Props = {
  text?: string;
  customLoader?: ReactNode;
};

export const FullScreenLoader: FC<React.PropsWithChildren<Props>> = ({
  text,
  customLoader,
}) => {
  return (
    <div
      role={'status'}
      title="loading"
      className="fixed top-0 left-0 w-screen h-screen flex flex-col items-center justify-center bg-gray-dark-56 z-dialog"
      aria-label="Loading"
    >
      {customLoader ?? <Loader size={40} />}
      {text && <p className="mt-8 font-subtitle3 text-gray-dark">{text}</p>}
    </div>
  );
};
