import { FC } from 'react';

import { Clock, PlaySquareOutline } from '@crehana/icons';
import { CustomNextImage } from '@crehana/next';
import { colors } from '@crehana/ui';

import { OrderDetail as OrderDetailType } from '@/model';
import { TextWithEllipsis } from '@/shared/components/ui/TextWithEllipsis';
import { useSharedTranslation } from '@/shared/hooks/useSharedTranslation';

type Props = {
  orderDetail: OrderDetailType;
};

export const OrderCard: FC<React.PropsWithChildren<Props>> = ({
  orderDetail: { image, title, courses, duration },
}) => {
  const { t } = useSharedTranslation();

  return (
    <>
      <div
        className="tw-relative tw-mt-2.4 tw-hidden tw-w-full tw-overflow-hidden tw-rounded-0.4 lg:tw-block"
        style={{ aspectRatio: '16/8' }}
      >
        <CustomNextImage src={image} layout="fill" objectFit="cover" />
      </div>
      <h4 className="font-subtitle3 my-16">
        <TextWithEllipsis>{title}</TextWithEllipsis>
      </h4>
      <div className="flex items-center" style={{ gap: 32 }}>
        <div className="flex items-center">
          <PlaySquareOutline color={colors.black} size={18} className="mr-12" />
          <p className="font-body4">
            {t('COURSES_LABEL', {
              interpolation: { coursesCount: courses },
            })}
          </p>
        </div>
        {duration && (
          <div className="flex items-center">
            <Clock color={colors.black} size={18} className="mr-12" />
            <p className="font-body4">{duration}</p>
          </div>
        )}
      </div>
    </>
  );
};
