import { useCallback } from 'react';

import BRANDING_QUERY from '../graphql/BrandingQuery.highereducation.graphql';
import {
  BrandingQuery,
  BrandingQueryVariables,
} from '../graphql/types/BrandingQuery';
import { Branding } from '../types/types';

import { useLazyFederationQuery } from './useLazyFederationQuery';

export const useBrandingQuery = () => {
  const [brandingQuery, { data, loading, error }] = useLazyFederationQuery<
    BrandingQuery,
    BrandingQueryVariables
  >(BRANDING_QUERY);

  const getBranding = useCallback(
    (organizationId: number | null | undefined) =>
      brandingQuery({
        variables: {
          organizationId,
        },
      }),
    [brandingQuery],
  );

  const branding: Branding | undefined = data?.marketplace
    ? {
        logo: data.marketplace.logo_image?.url ?? null,
        banner: data.marketplace.banner?.url ?? null,
        favicon: data.marketplace.favicon?.url ?? null,
        isotype: data.marketplace.isotype?.url ?? null,
        primaryColor: data.marketplace.primary_color || '',
        textOverPrimaryColor: data.marketplace.text_over_primary_color || '',
        instagram: data.marketplace.instagram_url,
        facebook: data.marketplace.facebook_url,
        linkedin: data.marketplace.linkedin_url,
        twitter: data.marketplace.twitter_url,
        name: data.marketplace.organization_name || '',
      }
    : undefined;

  return { getBranding, loading, error, branding };
};
