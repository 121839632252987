import {
  DocumentNode,
  QueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';

export const useLazyFederationQuery = <T, OperationVariables>(
  query: DocumentNode | TypedDocumentNode<T, OperationVariables>,
  options?: QueryHookOptions<T, OperationVariables> | undefined,
) =>
  useLazyQuery<T, OperationVariables>(query, {
    ...options,
    context: { clientName: 'v5' },
  });
