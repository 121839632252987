import { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CuiOptionType, Dropdown } from '@crehana/ui';

import { COUNTRIES } from '@/shared/constants';
import { useSharedTranslation } from '@/shared/hooks';
import { TKeys } from '@/shared/i18n';

import { getInitialCountry } from './utils';

type Props = {
  defaultCountry?: string;
  allowEmptyCountry?: boolean;
};

export const CountrySelect: FC<React.PropsWithChildren<Props>> = ({
  defaultCountry = '',
  allowEmptyCountry = false,
}) => {
  const { t } = useSharedTranslation();
  const { errors, register } = useFormContext<{
    country: string;
    countryIso: string;
  }>();
  const options = useMemo(() => {
    const countriesOptions = COUNTRIES.map(({ name, code }) => ({
      value: code,
      label: name,
    }));
    if (allowEmptyCountry)
      return [{ value: '', label: '' }, ...countriesOptions];
    return countriesOptions;
  }, [allowEmptyCountry]);

  const [selectedOption, setSelectedOption] = useState(
    getInitialCountry({ defaultCountry, options }),
  );

  const error = errors.country?.message
    ? t(errors.country.message as TKeys)
    : undefined;

  const handleSelectedChange = (
    selected?: CuiOptionType<string> | undefined,
  ) => {
    if (!selected) return;

    setSelectedOption(selected);
  };

  return (
    <>
      <input
        hidden
        ref={register({
          required: {
            value: !allowEmptyCountry,
            message: 'REQUIRED_FIELD_ERROR',
          },
        })}
        name="country"
        value={selectedOption?.label || ''}
        readOnly
      />
      <input
        hidden
        ref={register({
          required: {
            value: !allowEmptyCountry,
            message: 'REQUIRED_FIELD_ERROR',
          },
        })}
        name="countryIso"
        value={selectedOption?.value || ''}
        readOnly
      />

      <label className="inline-flex flex-col w-full" htmlFor="country-select">
        <span className="font-subtitle3 mb-8 text-base-main">
          {t('COUNTRY_LABEL')}
        </span>
      </label>
      <Dropdown<string>
        selectId="country-select"
        full
        onSelected={handleSelectedChange}
        selected={selectedOption}
        required={false}
        options={options}
        errorText={error}
      />
    </>
  );
};
