import { OrderStatus } from './types';

export const HIGHER_EDUCATION_KEY = 'higher-education-shared';

export const LOGIN_IMAGE_URL =
  'https://crehana-vercel-images-prd.imgix.net/public/higher-education-mf/139524b9-53bc-4ab5-b739-fab442799ac9-replace-image.png?auto=format&fit=clip';

export const HIGHER_EDUCATION_LOGO =
  'https://crehana-vercel-images-prd.imgix.net/public/340c238e-f5cd-4b45-b6d2-896dfdb372a1-color.png?auto=format&fit=clip';

export const IMG_USER =
  'https://crehana-vercel-images-prd.imgix.net/public/e1e6f4c2-5488-474b-ab30-f2a44cf157a6-user.png?auto=format&fit=clip';

export const ONE_MB_SIZE = 1024000;

export const COVER_IMAGE_MAX_SIZE = 1 * ONE_MB_SIZE;

export const DEFAULT_IMAGE_MB_SIZE = 1;
export const DEFAULT_IMAGE_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

export const COUNTRIES: { name: string; code: string }[] = [
  { name: 'Argentina', code: 'AR' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Brasil', code: 'BR' },
  { name: 'Chile', code: 'CL' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'México', code: 'MX' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'España', code: 'ES' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Venezuela', code: 'VE' },
];

export const PAYMENT_STATUS = new Map<number, OrderStatus>([
  [1, 'PAID'],
  [2, 'FAILED'],
  [3, 'PENDING'],
]);
