import { useCallback } from 'react';

import ORGANIZATION_QUERY from '../graphql/OrganizationQuery.highereducation.graphql';
import {
  OrganizationQuery,
  OrganizationQueryVariables,
} from '../graphql/types/OrganizationQuery';
import { CurrencyEnum } from '../types/graphql/globals.highereducation';
import { Organization } from '../types/types';

import { useLazyFederationQuery } from './useLazyFederationQuery';

export const useOrganizationQuery = () => {
  const [organizationQuery, { data, loading, error, called }] =
    useLazyFederationQuery<OrganizationQuery, OrganizationQueryVariables>(
      ORGANIZATION_QUERY,
    );

  const getOrganization = useCallback(
    (slug: string) =>
      organizationQuery({
        variables: {
          slug,
        },
      }),
    [organizationQuery],
  );

  const organization: Organization | undefined = data?.organization
    ? {
        id: data.organization.original_id,
        slug: data.organization.slug,
        name: data.organization.name,
        logo: data.organization.logo,
        currency:
          data.organization.marketplace_config.currencies[0] ??
          CurrencyEnum.USD,
        domain: '',
      }
    : undefined;

  return { getOrganization, loading, error, organization, called };
};
