import { FC } from 'react';

type Props = {
  maxCount: number;
  currentCount: number;
};

export const CharactersCount: FC<React.PropsWithChildren<Props>> = ({
  maxCount,
  currentCount,
}) => (
  <span className="font-label text-base-lighter">
    {currentCount}/{maxCount}
  </span>
);
