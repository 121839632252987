import styled from 'styled-components';

import { colors } from '@crehana/ui';

export const BrandStylesWrapper = styled.div<{
  primaryColor: string;
  textOverPrimaryColor: string;
}>`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

  --color-organization-primary-color: ${props => props.primaryColor};
  /* --color-primary-lighter: #9c86f9;
  --color-primary-light: #6f4ef6; */
  --color-primary-main: ${props => props.primaryColor};
  /* --color-primary-dark: #3417aa; */

  background-color: #f6f7f9;
  font-family: 'Poppins', sans-serif;

  .bg-primary:not(:disabled) {
    background-color: ${[props => props.primaryColor]};
    color: ${props => props.textOverPrimaryColor};
  }

  /* Fonts */

  .font-secure-payment {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    @media (min-width: 768px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .font-subtitle5 {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
  }

  .font-link1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  .md-font-link2 {
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.001em;
    }
  }

  .lg-font-subtitle3 {
    @media (min-width: 1224px) {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.001em;
    }
  }

  .font-label2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .button-primary {
    background-color: ${props => props.primaryColor};
    color: ${props => props.textOverPrimaryColor};
    border-radius: 10px;
    height: 48px;
    width: 100%;
  }

  .button-primary:disabled,
  .button-primary[disabled] {
    cursor: default;
    pointer-events: none;
    background-color: #c3cbd6;
    color: #8d8d9d;
  }

  .button-secondary {
    background-color: transparent;
    color: 'black';
    border-radius: 10px;
    height: 48px;
    width: 100%;
  }

  .cancel-btn {
    background-color: transparent !important;
    color: ${() => colors.baseLighter};
  }

  .caret-primary-main {
    caret-color: ${props => props.primaryColor};
  }

  .input-field {
    width: 100%;

    &:focus-within {
      > div:hover {
        border-color: ${props => props.primaryColor};
      }
    }

    > div {
      border: 1px solid #0f1019;
    }

    input {
      padding: 0 16px;
      border-radius: 10px;
    }

    input + div {
      height: 100%;
    }
  }

  .dropdown-field {
    height: 48px;

    .h-56 {
      height: 48px;
    }

    div > div {
      max-height: 192px;
    }
  }

  //Backgrounds
  .bg-neutral-dark-200 {
    background: #f0f2ff;
  }
`;
