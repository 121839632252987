import { ConfirmOrCancelModal } from '@/shared/components/ui/ConfirmOrCancelModal';

import { ConfirmOrCancelDialogProps } from '../ActionFeedbackContext';

export const createRenderMethodForConfirmOrCancelModal = (
  props: ConfirmOrCancelDialogProps & { type?: 'alertdialog' | 'dialog' },
  clear: () => void,
) => {
  const {
    onClose,
    onConfirm,
    onCancel,
    showClose,
    title,
    subtitle,
    cancelButtonLabel,
    confirmButtonLabel,
    type,
    body,
    topDecorator,
  } = props;

  const onConfirmClick = () => {
    if (onConfirm) onConfirm();
    clear();
  };

  const onCancelClick = () => {
    if (onCancel) onCancel();
    clear();
  };

  const onCloseClick = () => {
    if (onClose) onClose();
    clear();
  };

  // eslint-disable-next-line react/display-name
  return () => (
    <ConfirmOrCancelModal
      topDecorator={topDecorator}
      title={title}
      subtitle={subtitle}
      cancelButtonLabel={cancelButtonLabel}
      confirmButtonLabel={confirmButtonLabel}
      type={type}
      showClose={showClose}
      onCancel={onCancelClick}
      onConfirm={onConfirmClick}
      onClose={onCloseClick}
    >
      {body}
    </ConfirmOrCancelModal>
  );
};
