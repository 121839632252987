import CREATE_USER_MARKETPLACE from '@/shared/graphql/CreateUserMarketplace.highereducation.graphql';

import type {
  CreateUserMarketplace,
  CreateUserMarketplaceVariables,
} from '../graphql/types/CreateUserMarketplace';

import { useFederationMutation } from './useFederationMutation';
import { useOrganization } from './useOrganization';
import { useSharedTranslation } from './useSharedTranslation';

type Response = {
  success: boolean;
  error?: string;
};

type RegisterUserProps = {
  email: string;
  password: string;
  termsChecked: boolean;
};

export const useRegisterUser = () => {
  const { organizationSlug } = useOrganization();
  const { t } = useSharedTranslation();
  const [registerUserMutation, { loading }] = useFederationMutation<
    CreateUserMarketplace,
    CreateUserMarketplaceVariables
  >(CREATE_USER_MARKETPLACE);

  const registerUser = async ({
    email,
    password,
    termsChecked,
  }: RegisterUserProps): Promise<Response> => {
    if (!termsChecked)
      return { success: false, error: t('TEMRS_NOT_ACCEPTED_ERROR') };

    return registerUserMutation({
      variables: {
        email,
        password,
        organizationSlug,
      },
    })
      .then(res => {
        if (!res.data) return { success: false, error: t('ERROR_REGISTER') };

        const { __typename: responseType } = res.data.create_user;

        if (responseType === 'ErrorResponse')
          return { success: false, error: t('ERROR_REGISTER') };

        if (responseType === 'CreateUserResponse') return { success: true };

        return { success: false, error: t('ERROR_REGISTER') };
      })
      .catch(() => {
        return { success: false, error: t('ERROR_REGISTER') };
      });
  };

  return {
    registerUser,
    registering: loading,
  };
};
