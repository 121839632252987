import { useSharedTranslation } from '@/shared/hooks';

import Button from '../Button';
import { InternalLink } from '../InternalLink';

import CableInImage from './components/CableInImage';
import CableOutImage from './components/CableOut';
import { Heading } from './components/Heading';

const NotFoundView = () => {
  const { t } = useSharedTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-full text-center px-20 sm:px-0 flex-grow my-auto">
      <div className="relative flex items-center">
        <div className="relative flex items-center">
          <span className="absolute right-0">
            <CableInImage />
          </span>
        </div>
        <Heading className="font-h1 flex-shrink-0 px-40 sm:px-56">404</Heading>
        <div className="relative flex items-center">
          <span className="absolute left-0">
            <CableOutImage />
          </span>
        </div>
      </div>
      <h2 className="font-h2 text-24 mt-52 max-w-360 md:tw-max-w-full">
        {t('SUBTITLE_404')}
      </h2>
      <p className="font-body3 max-w-360 mt-24 md:tw-max-w-full">
        {t('CONTACT_404')}
      </p>
      <InternalLink href={'ecommerce'}>
        <Button className="tw-mt-2 md:tw-mt-6.4" variant="secondary">
          {t('BACK_TO_HOME')}
        </Button>
      </InternalLink>
    </div>
  );
};

export default NotFoundView;
