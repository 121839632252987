import { ConfirmModal } from '@/shared/components/ui/ConfirmModal';

import { ConfirmationDialogProps } from '../ActionFeedbackContext';

export const createRenderMethodForConfirmModal = (
  props: ConfirmationDialogProps,
  clear: () => void,
) => {
  const {
    onConfirm,
    onClose,
    title,
    subtitle,
    confirmButtonLabel,
    body,
    showClose,
    topDecorator,
  } = props;

  const onClick = () => {
    if (onConfirm) onConfirm();
    clear();
  };

  const onCloseClick = () => {
    if (onClose) onClose();
    clear();
  };

  // eslint-disable-next-line react/display-name
  return () => (
    <ConfirmModal
      showClose={showClose}
      topDecorator={topDecorator}
      onClose={onCloseClick}
      title={title}
      subtitle={subtitle}
      confirmButtonLabel={confirmButtonLabel}
      onConfirm={onClick}
    >
      {body}
    </ConfirmModal>
  );
};
