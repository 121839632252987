import { FC } from 'react';
import clsx from 'clsx';

import { useMe } from '@crehana/auth';
import { CustomNextImage } from '@crehana/next';

type Props = {
  className?: string;
};

export const UserImage: FC<React.PropsWithChildren<Props>> = ({
  className = '',
}) => {
  const { me } = useMe();

  if (!me || !me.profilePictureUrl) return null;

  return (
    <div
      className={clsx(
        'relative w-40 h-40 rounded-full overflow-hidden',
        className,
      )}
    >
      <CustomNextImage
        src={me.profilePictureUrl}
        alt={me.fullName || 'User'}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
    </div>
  );
};
