import { FC, lazy, ReactNode, Suspense, useEffect, useState } from 'react';
import Link from 'next/link';

type Props = {
  socialMedia: SocialMedia;
  link: string;
};

export type SocialMedia = 'Facebook' | 'Twitter' | 'Instagram' | 'Linkedin';

export const FooterSocialMediaLink: FC<React.PropsWithChildren<Props>> = ({
  socialMedia,
  link,
}) => {
  const [SocialIcon, setSocialIcon] = useState<ReactNode | null>(null);

  useEffect(() => {
    // The lazy/Suspense is used inside the useEffect because it's not supported server side in the current version
    // This is a workaround
    const Icon = lazy(() =>
      import('@crehana/icons').then(module => {
        switch (socialMedia) {
          default:
            return { default: module[socialMedia] };
        }
      }),
    );

    setSocialIcon(
      <Suspense fallback={null}>
        <Icon color="#73757A" size={20} />
      </Suspense>,
    );
  }, [socialMedia]);

  return (
    <Link
      href={`https://${link.replace('https://', '')}`}
      className="flex items-center justify-center"
      rel={'nofollow noopener noreferrer'}
      target={'_blank'}
    >
      {SocialIcon}
    </Link>
  );
};
