import { CuiOptionType } from '@crehana/ui';

type Props = {
  defaultCountry: string;
};

export const getInitialCountry = ({
  defaultCountry,
  options,
}: Props & { options: CuiOptionType[] }): CuiOptionType | undefined =>
  options.find(
    country => country.value.toUpperCase() === defaultCountry.toUpperCase(),
  );
