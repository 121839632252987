import styled from 'styled-components';

export const GridColumn = styled.div<{
  span?: number;
  smSpan?: number;
  mdSpan?: number;
  lgSpan?: number;
}>`
  grid-column-end: ${props => `span ${props.span || 12}`};

  @media (min-width: 512px) {
    ${({ smSpan }) => (smSpan ? `grid-column-end: span ${smSpan}` : '')};
  }

  @media (min-width: 768px) {
    ${({ mdSpan }) => (mdSpan ? `grid-column-end: span ${mdSpan}` : '')};
  }

  @media (min-width: 1366px) {
    ${({ lgSpan }) => (lgSpan ? `grid-column-end: span ${lgSpan}` : '')};
  }
`;
