import {
  DocumentNode,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';

export const useFederationQuery = <T, OperationVariables = {}>(
  query: DocumentNode | TypedDocumentNode<T, OperationVariables>,
  options?: QueryHookOptions<T, OperationVariables> | undefined,
) =>
  useQuery<T, OperationVariables>(query, {
    ...options,
    context: { clientName: 'v5' },
  });
