import { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';

type ButtonVariant = 'primary' | 'secondary' | 'cancel';

type TPrimaryButtonProps = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariant;
    full?: boolean;
  }
>;

const variantClasses: Record<ButtonVariant, string> = {
  cancel: '',
  primary: 'bg-primary',
  secondary: 'tw-border-[2px]',
};

const hasWidthClass = (className?: string) =>
  className?.includes('tw-w-') || false;

const Button = forwardRef<HTMLButtonElement, TPrimaryButtonProps>(
  (
    {
      children,
      variant = 'primary',
      className,
      type = 'button',
      disabled,
      full,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={clsx(
        'tw-btn-s tw-relative tw-items-center tw-justify-center tw-overflow-hidden tw-px-1.6 tw-text-center',
        'disabled:tw-border-0 disabled:tw-bg-neutral-light-600 disabled:tw-text-neutral-light-800 disabled:before:tw-hidden',
        'before:tw-absolute before:tw-left-0 before:tw-h-full before:tw-w-full before:hover:tw-bg-neutral-light-100 before:hover:tw-opacity-10',
        variantClasses[variant],
        !hasWidthClass(className) && !full && 'tw-w-fit',
        !hasWidthClass(className) && full && 'tw-w-full',
        className,
      )}
      {...props}
    >
      {children}
    </button>
  ),
);

Button.displayName = 'Button';

export default Button;
