import { HIGHER_EDUCATION_KEY } from '../constants';

const pt = {
  [HIGHER_EDUCATION_KEY]: {
    SEARCH_PLACEHOLDER: '¿Qué quieres aprender hoy?',
    LOGIN: 'Iniciar sesión',
    REGISTER: 'Registrarse',
    ENTER: 'Entrar',
    EMAIL_LABEL: 'Correo electrónico',
    PASSWORD_LABEL: 'Contraseña',
    LOGIN_GREETING: 'Bienvenido de vuelta',
    REGISTER_GREETING: 'Unete hoy',
    REGISTER_GREETING_2: 'Regístrate para acceder a tu ruta',
    LOGIN_SUBTITLE: '¡Nos alegra volverte a ver!',
    REGISTER_SUBTITLE: 'Da un paso adelante a tu carrera profesional',
    REGISTER_SUBTITLE_2:
      'Es necesario registrarse para continuar con tu compra',
    LOGIN_BUTTON: 'Iniciar sesión',
    LOGOUT_BUTTON: 'Cerrar sesión',
    REGISTER_BUTTON: 'Crear cuenta',
    LOGIN_TOGGLE_TYPE: '¿Aún no tienes una cuenta?',
    LOGIN_TOGGLE_TYPE_SPAN: 'Regístrate',
    REGISTER_TOGGLE_TYPE: '¿Tienes una cuenta?',
    REGISTER_TOGGLE_TYPE_SPAN: 'Iniciar sesión',
    INVALID_IMAGE_SIZE: 'La imagen supera el tamaño máximo.',
    INVALID_IMAGE_TYPE: 'El archivo no es una imagen válida.',
    FOOTER_COPYRIGHTS:
      'Copyright © Crehana {{currentYear}} - Todos los derechos reservados',
    TERMS_AND_CONDITIONS_LABEL:
      'Acepto los Términos, Condiciones y Políticas de Crehana.',
    INPUT_REQUIRED: 'Campo requerido',
    AUTH_VALIDATION_LEGALS_REQUIRED:
      'Debes aceptar los Términos, Condiciones y Políticas para continuar.',
    VALIDATE_MAX_PASSWORD: 'Tu contraseña debe de tener máximo 6 caracteres',
    VALIDATE_MIN_PASSWORD: 'Tu contraseña debe de tener al menos 6 caracteres',
    VALIDATE_FORMAT_EMAIL: 'Por favor, ingresa un email válido.',
    PASSWORD_DOES_NOT_MATCH: 'La contraseña no coincide',
    HELP_CENTER: 'Centro de ayuda',
    CONFIG_PROFILE: 'Configurar Perfil',
    MY_PATH_NAV: 'Mis rutas de aprendizaje',
    SHOPPING_HISTORY: 'Historial de compra',
    MY_ACCOUNT: 'Mi cuenta',
    MODAL_PRIMARY_BUTTON_LABEL: 'Entendido',
    MODAL_CANCEL_BUTTON_LABEL: 'Cancelar',
    ERROR_LOGIN: 'Usuario o contraseña incorrectas',

    SEARCH_PRODUCT_LABEL: 'Buscar producto',
  },
};

export default pt;
